export default function WindowsIcon({ width, height, color }) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons">
                <g id="Vector">
                    <path d="M16.0741 3H16.8148V3.72H16.0741V3Z" fill="white" />
                    <path d="M16.8148 3H17.5556V3.72H16.8148V3Z" fill="white" />
                    <path d="M17.5556 3H18.2963V3.72H17.5556V3Z" fill="white" />
                    <path d="M18.2963 3H19.037V3.72H18.2963V3Z" fill="white" />
                    <path d="M19.037 3H19.7778V3.72H19.037V3Z" fill="white" />
                    <path d="M19.7778 3H20.5185V3.72H19.7778V3Z" fill="white" />
                    <path d="M20.5185 3H21.2593V3.72H20.5185V3Z" fill="white" />
                    <path d="M21.2593 3H22V3.72H21.2593V3Z" fill="white" />
                    <path d="M10.1481 3.72H10.8889V4.44H10.1481V3.72Z" fill="white" />
                    <path d="M12.3704 3.72H13.1111V4.44H12.3704V3.72Z" fill="white" />
                    <path d="M13.1111 3.72H13.8519V4.44H13.1111V3.72Z" fill="white" />
                    <path d="M13.8519 3.72H14.5926V4.44H13.8519V3.72Z" fill="white" />
                    <path d="M14.5926 3.72H15.3333V4.44H14.5926V3.72Z" fill="white" />
                    <path d="M15.3333 3.72H16.0741V4.44H15.3333V3.72Z" fill="white" />
                    <path d="M16.0741 3.72H16.8148V4.44H16.0741V3.72Z" fill="white" />
                    <path d="M16.8148 3.72H17.5556V4.44H16.8148V3.72Z" fill="white" />
                    <path d="M17.5556 3.72H18.2963V4.44H17.5556V3.72Z" fill="white" />
                    <path d="M18.2963 3.72H19.037V4.44H18.2963V3.72Z" fill="white" />
                    <path d="M19.037 3.72H19.7778V4.44H19.037V3.72Z" fill="white" />
                    <path d="M19.7778 3.72H20.5185V4.44H19.7778V3.72Z" fill="white" />
                    <path d="M20.5185 3.72H21.2593V4.44H20.5185V3.72Z" fill="white" />
                    <path d="M21.2593 3.72H22V4.44H21.2593V3.72Z" fill="white" />
                    <path d="M4.22222 4.44H4.96296V5.16H4.22222V4.44Z" fill="white" />
                    <path d="M4.96296 4.44H5.7037V5.16H4.96296V4.44Z" fill="white" />
                    <path d="M5.7037 4.44H6.44444V5.16H5.7037V4.44Z" fill="white" />
                    <path d="M6.44444 4.44H7.18519V5.16H6.44444V4.44Z" fill="white" />
                    <path d="M7.18519 4.44H7.92593V5.16H7.18519V4.44Z" fill="white" />
                    <path d="M7.92593 4.44H8.66667V5.16H7.92593V4.44Z" fill="white" />
                    <path d="M8.66667 4.44H9.40741V5.16H8.66667V4.44Z" fill="white" />
                    <path d="M9.40741 4.44H10.1481V5.16H9.40741V4.44Z" fill="white" />
                    <path d="M10.1481 4.44H10.8889V5.16H10.1481V4.44Z" fill="white" />
                    <path d="M12.3704 4.44H13.1111V5.16H12.3704V4.44Z" fill="white" />
                    <path d="M13.1111 4.44H13.8519V5.16H13.1111V4.44Z" fill="white" />
                    <path d="M13.8519 4.44H14.5926V5.16H13.8519V4.44Z" fill="white" />
                    <path d="M14.5926 4.44H15.3333V5.16H14.5926V4.44Z" fill="white" />
                    <path d="M15.3333 4.44H16.0741V5.16H15.3333V4.44Z" fill="white" />
                    <path d="M16.0741 4.44H16.8148V5.16H16.0741V4.44Z" fill="white" />
                    <path d="M16.8148 4.44H17.5556V5.16H16.8148V4.44Z" fill="white" />
                    <path d="M17.5556 4.44H18.2963V5.16H17.5556V4.44Z" fill="white" />
                    <path d="M18.2963 4.44H19.037V5.16H18.2963V4.44Z" fill="white" />
                    <path d="M19.037 4.44H19.7778V5.16H19.037V4.44Z" fill="white" />
                    <path d="M19.7778 4.44H20.5185V5.16H19.7778V4.44Z" fill="white" />
                    <path d="M20.5185 4.44H21.2593V5.16H20.5185V4.44Z" fill="white" />
                    <path d="M21.2593 4.44H22V5.16H21.2593V4.44Z" fill="white" />
                    <path d="M2 5.16H2.74074V5.88H2V5.16Z" fill="white" />
                    <path d="M2.74074 5.16H3.48148V5.88H2.74074V5.16Z" fill="white" />
                    <path d="M3.48148 5.16H4.22222V5.88H3.48148V5.16Z" fill="white" />
                    <path d="M4.22222 5.16H4.96296V5.88H4.22222V5.16Z" fill="white" />
                    <path d="M4.96296 5.16H5.7037V5.88H4.96296V5.16Z" fill="white" />
                    <path d="M5.7037 5.16H6.44444V5.88H5.7037V5.16Z" fill="white" />
                    <path d="M6.44444 5.16H7.18519V5.88H6.44444V5.16Z" fill="white" />
                    <path d="M7.18519 5.16H7.92593V5.88H7.18519V5.16Z" fill="white" />
                    <path d="M7.92593 5.16H8.66667V5.88H7.92593V5.16Z" fill="white" />
                    <path d="M8.66667 5.16H9.40741V5.88H8.66667V5.16Z" fill="white" />
                    <path d="M9.40741 5.16H10.1481V5.88H9.40741V5.16Z" fill="white" />
                    <path d="M10.1481 5.16H10.8889V5.88H10.1481V5.16Z" fill="white" />
                    <path d="M12.3704 5.16H13.1111V5.88H12.3704V5.16Z" fill="white" />
                    <path d="M13.1111 5.16H13.8519V5.88H13.1111V5.16Z" fill="white" />
                    <path d="M13.8519 5.16H14.5926V5.88H13.8519V5.16Z" fill="white" />
                    <path d="M14.5926 5.16H15.3333V5.88H14.5926V5.16Z" fill="white" />
                    <path d="M15.3333 5.16H16.0741V5.88H15.3333V5.16Z" fill="white" />
                    <path d="M16.0741 5.16H16.8148V5.88H16.0741V5.16Z" fill="white" />
                    <path d="M16.8148 5.16H17.5556V5.88H16.8148V5.16Z" fill="white" />
                    <path d="M17.5556 5.16H18.2963V5.88H17.5556V5.16Z" fill="white" />
                    <path d="M18.2963 5.16H19.037V5.88H18.2963V5.16Z" fill="white" />
                    <path d="M19.037 5.16H19.7778V5.88H19.037V5.16Z" fill="white" />
                    <path d="M19.7778 5.16H20.5185V5.88H19.7778V5.16Z" fill="white" />
                    <path d="M20.5185 5.16H21.2593V5.88H20.5185V5.16Z" fill="white" />
                    <path d="M21.2593 5.16H22V5.88H21.2593V5.16Z" fill="white" />
                    <path d="M2 5.88H2.74074V6.6H2V5.88Z" fill="white" />
                    <path d="M2.74074 5.88H3.48148V6.6H2.74074V5.88Z" fill="white" />
                    <path d="M3.48148 5.88H4.22222V6.6H3.48148V5.88Z" fill="white" />
                    <path d="M4.22222 5.88H4.96296V6.6H4.22222V5.88Z" fill="white" />
                    <path d="M4.96296 5.88H5.7037V6.6H4.96296V5.88Z" fill="white" />
                    <path d="M5.7037 5.88H6.44444V6.6H5.7037V5.88Z" fill="white" />
                    <path d="M6.44444 5.88H7.18519V6.6H6.44444V5.88Z" fill="white" />
                    <path d="M7.18519 5.88H7.92593V6.6H7.18519V5.88Z" fill="white" />
                    <path d="M7.92593 5.88H8.66667V6.6H7.92593V5.88Z" fill="white" />
                    <path d="M8.66667 5.88H9.40741V6.6H8.66667V5.88Z" fill="white" />
                    <path d="M9.40741 5.88H10.1481V6.6H9.40741V5.88Z" fill="white" />
                    <path d="M10.1481 5.88H10.8889V6.6H10.1481V5.88Z" fill="white" />
                    <path d="M12.3704 5.88H13.1111V6.6H12.3704V5.88Z" fill="white" />
                    <path d="M13.1111 5.88H13.8519V6.6H13.1111V5.88Z" fill="white" />
                    <path d="M13.8519 5.88H14.5926V6.6H13.8519V5.88Z" fill="white" />
                    <path d="M14.5926 5.88H15.3333V6.6H14.5926V5.88Z" fill="white" />
                    <path d="M15.3333 5.88H16.0741V6.6H15.3333V5.88Z" fill="white" />
                    <path d="M16.0741 5.88H16.8148V6.6H16.0741V5.88Z" fill="white" />
                    <path d="M16.8148 5.88H17.5556V6.6H16.8148V5.88Z" fill="white" />
                    <path d="M17.5556 5.88H18.2963V6.6H17.5556V5.88Z" fill="white" />
                    <path d="M18.2963 5.88H19.037V6.6H18.2963V5.88Z" fill="white" />
                    <path d="M19.037 5.88H19.7778V6.6H19.037V5.88Z" fill="white" />
                    <path d="M19.7778 5.88H20.5185V6.6H19.7778V5.88Z" fill="white" />
                    <path d="M20.5185 5.88H21.2593V6.6H20.5185V5.88Z" fill="white" />
                    <path d="M21.2593 5.88H22V6.6H21.2593V5.88Z" fill="white" />
                    <path d="M2 6.6H2.74074V7.32H2V6.6Z" fill="white" />
                    <path d="M2.74074 6.6H3.48148V7.32H2.74074V6.6Z" fill="white" />
                    <path d="M3.48148 6.6H4.22222V7.32H3.48148V6.6Z" fill="white" />
                    <path d="M4.22222 6.6H4.96296V7.32H4.22222V6.6Z" fill="white" />
                    <path d="M4.96296 6.6H5.7037V7.32H4.96296V6.6Z" fill="white" />
                    <path d="M5.7037 6.6H6.44444V7.32H5.7037V6.6Z" fill="white" />
                    <path d="M6.44444 6.6H7.18519V7.32H6.44444V6.6Z" fill="white" />
                    <path d="M7.18519 6.6H7.92593V7.32H7.18519V6.6Z" fill="white" />
                    <path d="M7.92593 6.6H8.66667V7.32H7.92593V6.6Z" fill="white" />
                    <path d="M8.66667 6.6H9.40741V7.32H8.66667V6.6Z" fill="white" />
                    <path d="M9.40741 6.6H10.1481V7.32H9.40741V6.6Z" fill="white" />
                    <path d="M10.1481 6.6H10.8889V7.32H10.1481V6.6Z" fill="white" />
                    <path d="M12.3704 6.6H13.1111V7.32H12.3704V6.6Z" fill="white" />
                    <path d="M13.1111 6.6H13.8519V7.32H13.1111V6.6Z" fill="white" />
                    <path d="M13.8519 6.6H14.5926V7.32H13.8519V6.6Z" fill="white" />
                    <path d="M14.5926 6.6H15.3333V7.32H14.5926V6.6Z" fill="white" />
                    <path d="M15.3333 6.6H16.0741V7.32H15.3333V6.6Z" fill="white" />
                    <path d="M16.0741 6.6H16.8148V7.32H16.0741V6.6Z" fill="white" />
                    <path d="M16.8148 6.6H17.5556V7.32H16.8148V6.6Z" fill="white" />
                    <path d="M17.5556 6.6H18.2963V7.32H17.5556V6.6Z" fill="white" />
                    <path d="M18.2963 6.6H19.037V7.32H18.2963V6.6Z" fill="white" />
                    <path d="M19.037 6.6H19.7778V7.32H19.037V6.6Z" fill="white" />
                    <path d="M19.7778 6.6H20.5185V7.32H19.7778V6.6Z" fill="white" />
                    <path d="M20.5185 6.6H21.2593V7.32H20.5185V6.6Z" fill="white" />
                    <path d="M21.2593 6.6H22V7.32H21.2593V6.6Z" fill="white" />
                    <path d="M2 7.32H2.74074V8.04H2V7.32Z" fill="white" />
                    <path d="M2.74074 7.32H3.48148V8.04H2.74074V7.32Z" fill="white" />
                    <path d="M3.48148 7.32H4.22222V8.04H3.48148V7.32Z" fill="white" />
                    <path d="M4.22222 7.32H4.96296V8.04H4.22222V7.32Z" fill="white" />
                    <path d="M4.96296 7.32H5.7037V8.04H4.96296V7.32Z" fill="white" />
                    <path d="M5.7037 7.32H6.44444V8.04H5.7037V7.32Z" fill="white" />
                    <path d="M6.44444 7.32H7.18519V8.04H6.44444V7.32Z" fill="white" />
                    <path d="M7.18519 7.32H7.92593V8.04H7.18519V7.32Z" fill="white" />
                    <path d="M7.92593 7.32H8.66667V8.04H7.92593V7.32Z" fill="white" />
                    <path d="M8.66667 7.32H9.40741V8.04H8.66667V7.32Z" fill="white" />
                    <path d="M9.40741 7.32H10.1481V8.04H9.40741V7.32Z" fill="white" />
                    <path d="M10.1481 7.32H10.8889V8.04H10.1481V7.32Z" fill="white" />
                    <path d="M12.3704 7.32H13.1111V8.04H12.3704V7.32Z" fill="white" />
                    <path d="M13.1111 7.32H13.8519V8.04H13.1111V7.32Z" fill="white" />
                    <path d="M13.8519 7.32H14.5926V8.04H13.8519V7.32Z" fill="white" />
                    <path d="M14.5926 7.32H15.3333V8.04H14.5926V7.32Z" fill="white" />
                    <path d="M15.3333 7.32H16.0741V8.04H15.3333V7.32Z" fill="white" />
                    <path d="M16.0741 7.32H16.8148V8.04H16.0741V7.32Z" fill="white" />
                    <path d="M16.8148 7.32H17.5556V8.04H16.8148V7.32Z" fill="white" />
                    <path d="M17.5556 7.32H18.2963V8.04H17.5556V7.32Z" fill="white" />
                    <path d="M18.2963 7.32H19.037V8.04H18.2963V7.32Z" fill="white" />
                    <path d="M19.037 7.32H19.7778V8.04H19.037V7.32Z" fill="white" />
                    <path d="M19.7778 7.32H20.5185V8.04H19.7778V7.32Z" fill="white" />
                    <path d="M20.5185 7.32H21.2593V8.04H20.5185V7.32Z" fill="white" />
                    <path d="M21.2593 7.32H22V8.04H21.2593V7.32Z" fill="white" />
                    <path d="M2 8.04H2.74074V8.76H2V8.04Z" fill="white" />
                    <path d="M2.74074 8.04H3.48148V8.76H2.74074V8.04Z" fill="white" />
                    <path d="M3.48148 8.04H4.22222V8.76H3.48148V8.04Z" fill="white" />
                    <path d="M4.22222 8.04H4.96296V8.76H4.22222V8.04Z" fill="white" />
                    <path d="M4.96296 8.04H5.7037V8.76H4.96296V8.04Z" fill="white" />
                    <path d="M5.7037 8.04H6.44444V8.76H5.7037V8.04Z" fill="white" />
                    <path d="M6.44444 8.04H7.18519V8.76H6.44444V8.04Z" fill="white" />
                    <path d="M7.18519 8.04H7.92593V8.76H7.18519V8.04Z" fill="white" />
                    <path d="M7.92593 8.04H8.66667V8.76H7.92593V8.04Z" fill="white" />
                    <path d="M8.66667 8.04H9.40741V8.76H8.66667V8.04Z" fill="white" />
                    <path d="M9.40741 8.04H10.1481V8.76H9.40741V8.04Z" fill="white" />
                    <path d="M10.1481 8.04H10.8889V8.76H10.1481V8.04Z" fill="white" />
                    <path d="M12.3704 8.04H13.1111V8.76H12.3704V8.04Z" fill="white" />
                    <path d="M13.1111 8.04H13.8519V8.76H13.1111V8.04Z" fill="white" />
                    <path d="M13.8519 8.04H14.5926V8.76H13.8519V8.04Z" fill="white" />
                    <path d="M14.5926 8.04H15.3333V8.76H14.5926V8.04Z" fill="white" />
                    <path d="M15.3333 8.04H16.0741V8.76H15.3333V8.04Z" fill="white" />
                    <path d="M16.0741 8.04H16.8148V8.76H16.0741V8.04Z" fill="white" />
                    <path d="M16.8148 8.04H17.5556V8.76H16.8148V8.04Z" fill="white" />
                    <path d="M17.5556 8.04H18.2963V8.76H17.5556V8.04Z" fill="white" />
                    <path d="M18.2963 8.04H19.037V8.76H18.2963V8.04Z" fill="white" />
                    <path d="M19.037 8.04H19.7778V8.76H19.037V8.04Z" fill="white" />
                    <path d="M19.7778 8.04H20.5185V8.76H19.7778V8.04Z" fill="white" />
                    <path d="M20.5185 8.04H21.2593V8.76H20.5185V8.04Z" fill="white" />
                    <path d="M21.2593 8.04H22V8.76H21.2593V8.04Z" fill="white" />
                    <path d="M2 8.76H2.74074V9.48H2V8.76Z" fill="white" />
                    <path d="M2.74074 8.76H3.48148V9.48H2.74074V8.76Z" fill="white" />
                    <path d="M3.48148 8.76H4.22222V9.48H3.48148V8.76Z" fill="white" />
                    <path d="M4.22222 8.76H4.96296V9.48H4.22222V8.76Z" fill="white" />
                    <path d="M4.96296 8.76H5.7037V9.48H4.96296V8.76Z" fill="white" />
                    <path d="M5.7037 8.76H6.44444V9.48H5.7037V8.76Z" fill="white" />
                    <path d="M6.44444 8.76H7.18519V9.48H6.44444V8.76Z" fill="white" />
                    <path d="M7.18519 8.76H7.92593V9.48H7.18519V8.76Z" fill="white" />
                    <path d="M7.92593 8.76H8.66667V9.48H7.92593V8.76Z" fill="white" />
                    <path d="M8.66667 8.76H9.40741V9.48H8.66667V8.76Z" fill="white" />
                    <path d="M9.40741 8.76H10.1481V9.48H9.40741V8.76Z" fill="white" />
                    <path d="M10.1481 8.76H10.8889V9.48H10.1481V8.76Z" fill="white" />
                    <path d="M12.3704 8.76H13.1111V9.48H12.3704V8.76Z" fill="white" />
                    <path d="M13.1111 8.76H13.8519V9.48H13.1111V8.76Z" fill="white" />
                    <path d="M13.8519 8.76H14.5926V9.48H13.8519V8.76Z" fill="white" />
                    <path d="M14.5926 8.76H15.3333V9.48H14.5926V8.76Z" fill="white" />
                    <path d="M15.3333 8.76H16.0741V9.48H15.3333V8.76Z" fill="white" />
                    <path d="M16.0741 8.76H16.8148V9.48H16.0741V8.76Z" fill="white" />
                    <path d="M16.8148 8.76H17.5556V9.48H16.8148V8.76Z" fill="white" />
                    <path d="M17.5556 8.76H18.2963V9.48H17.5556V8.76Z" fill="white" />
                    <path d="M18.2963 8.76H19.037V9.48H18.2963V8.76Z" fill="white" />
                    <path d="M19.037 8.76H19.7778V9.48H19.037V8.76Z" fill="white" />
                    <path d="M19.7778 8.76H20.5185V9.48H19.7778V8.76Z" fill="white" />
                    <path d="M20.5185 8.76H21.2593V9.48H20.5185V8.76Z" fill="white" />
                    <path d="M21.2593 8.76H22V9.48H21.2593V8.76Z" fill="white" />
                    <path d="M2 9.48H2.74074V10.2H2V9.48Z" fill="white" />
                    <path d="M2.74074 9.48H3.48148V10.2H2.74074V9.48Z" fill="white" />
                    <path d="M3.48148 9.48H4.22222V10.2H3.48148V9.48Z" fill="white" />
                    <path d="M4.22222 9.48H4.96296V10.2H4.22222V9.48Z" fill="white" />
                    <path d="M4.96296 9.48H5.7037V10.2H4.96296V9.48Z" fill="white" />
                    <path d="M5.7037 9.48H6.44444V10.2H5.7037V9.48Z" fill="white" />
                    <path d="M6.44444 9.48H7.18519V10.2H6.44444V9.48Z" fill="white" />
                    <path d="M7.18519 9.48H7.92593V10.2H7.18519V9.48Z" fill="white" />
                    <path d="M7.92593 9.48H8.66667V10.2H7.92593V9.48Z" fill="white" />
                    <path d="M8.66667 9.48H9.40741V10.2H8.66667V9.48Z" fill="white" />
                    <path d="M9.40741 9.48H10.1481V10.2H9.40741V9.48Z" fill="white" />
                    <path d="M10.1481 9.48H10.8889V10.2H10.1481V9.48Z" fill="white" />
                    <path d="M12.3704 9.48H13.1111V10.2H12.3704V9.48Z" fill="white" />
                    <path d="M13.1111 9.48H13.8519V10.2H13.1111V9.48Z" fill="white" />
                    <path d="M13.8519 9.48H14.5926V10.2H13.8519V9.48Z" fill="white" />
                    <path d="M14.5926 9.48H15.3333V10.2H14.5926V9.48Z" fill="white" />
                    <path d="M15.3333 9.48H16.0741V10.2H15.3333V9.48Z" fill="white" />
                    <path d="M16.0741 9.48H16.8148V10.2H16.0741V9.48Z" fill="white" />
                    <path d="M16.8148 9.48H17.5556V10.2H16.8148V9.48Z" fill="white" />
                    <path d="M17.5556 9.48H18.2963V10.2H17.5556V9.48Z" fill="white" />
                    <path d="M18.2963 9.48H19.037V10.2H18.2963V9.48Z" fill="white" />
                    <path d="M19.037 9.48H19.7778V10.2H19.037V9.48Z" fill="white" />
                    <path d="M19.7778 9.48H20.5185V10.2H19.7778V9.48Z" fill="white" />
                    <path d="M20.5185 9.48H21.2593V10.2H20.5185V9.48Z" fill="white" />
                    <path d="M21.2593 9.48H22V10.2H21.2593V9.48Z" fill="white" />
                    <path d="M2 10.2H2.74074V10.92H2V10.2Z" fill="white" />
                    <path d="M2.74074 10.2H3.48148V10.92H2.74074V10.2Z" fill="white" />
                    <path d="M3.48148 10.2H4.22222V10.92H3.48148V10.2Z" fill="white" />
                    <path d="M4.22222 10.2H4.96296V10.92H4.22222V10.2Z" fill="white" />
                    <path d="M4.96296 10.2H5.7037V10.92H4.96296V10.2Z" fill="white" />
                    <path d="M5.7037 10.2H6.44444V10.92H5.7037V10.2Z" fill="white" />
                    <path d="M6.44444 10.2H7.18519V10.92H6.44444V10.2Z" fill="white" />
                    <path d="M7.18519 10.2H7.92593V10.92H7.18519V10.2Z" fill="white" />
                    <path d="M7.92593 10.2H8.66667V10.92H7.92593V10.2Z" fill="white" />
                    <path d="M8.66667 10.2H9.40741V10.92H8.66667V10.2Z" fill="white" />
                    <path d="M9.40741 10.2H10.1481V10.92H9.40741V10.2Z" fill="white" />
                    <path d="M10.1481 10.2H10.8889V10.92H10.1481V10.2Z" fill="white" />
                    <path d="M12.3704 10.2H13.1111V10.92H12.3704V10.2Z" fill="white" />
                    <path d="M13.1111 10.2H13.8519V10.92H13.1111V10.2Z" fill="white" />
                    <path d="M13.8519 10.2H14.5926V10.92H13.8519V10.2Z" fill="white" />
                    <path d="M14.5926 10.2H15.3333V10.92H14.5926V10.2Z" fill="white" />
                    <path d="M15.3333 10.2H16.0741V10.92H15.3333V10.2Z" fill="white" />
                    <path d="M16.0741 10.2H16.8148V10.92H16.0741V10.2Z" fill="white" />
                    <path d="M16.8148 10.2H17.5556V10.92H16.8148V10.2Z" fill="white" />
                    <path d="M17.5556 10.2H18.2963V10.92H17.5556V10.2Z" fill="white" />
                    <path d="M18.2963 10.2H19.037V10.92H18.2963V10.2Z" fill="white" />
                    <path d="M19.037 10.2H19.7778V10.92H19.037V10.2Z" fill="white" />
                    <path d="M19.7778 10.2H20.5185V10.92H19.7778V10.2Z" fill="white" />
                    <path d="M20.5185 10.2H21.2593V10.92H20.5185V10.2Z" fill="white" />
                    <path d="M21.2593 10.2H22V10.92H21.2593V10.2Z" fill="white" />
                    <path d="M2 12.36H2.74074V13.08H2V12.36Z" fill="white" />
                    <path d="M2.74074 12.36H3.48148V13.08H2.74074V12.36Z" fill="white" />
                    <path d="M3.48148 12.36H4.22222V13.08H3.48148V12.36Z" fill="white" />
                    <path d="M4.22222 12.36H4.96296V13.08H4.22222V12.36Z" fill="white" />
                    <path d="M4.96296 12.36H5.7037V13.08H4.96296V12.36Z" fill="white" />
                    <path d="M5.7037 12.36H6.44444V13.08H5.7037V12.36Z" fill="white" />
                    <path d="M6.44444 12.36H7.18519V13.08H6.44444V12.36Z" fill="white" />
                    <path d="M7.18519 12.36H7.92593V13.08H7.18519V12.36Z" fill="white" />
                    <path d="M7.92593 12.36H8.66667V13.08H7.92593V12.36Z" fill="white" />
                    <path d="M8.66667 12.36H9.40741V13.08H8.66667V12.36Z" fill="white" />
                    <path d="M9.40741 12.36H10.1481V13.08H9.40741V12.36Z" fill="white" />
                    <path d="M10.1481 12.36H10.8889V13.08H10.1481V12.36Z" fill="white" />
                    <path d="M12.3704 12.36H13.1111V13.08H12.3704V12.36Z" fill="white" />
                    <path d="M13.1111 12.36H13.8519V13.08H13.1111V12.36Z" fill="white" />
                    <path d="M13.8519 12.36H14.5926V13.08H13.8519V12.36Z" fill="white" />
                    <path d="M14.5926 12.36H15.3333V13.08H14.5926V12.36Z" fill="white" />
                    <path d="M15.3333 12.36H16.0741V13.08H15.3333V12.36Z" fill="white" />
                    <path d="M16.0741 12.36H16.8148V13.08H16.0741V12.36Z" fill="white" />
                    <path d="M16.8148 12.36H17.5556V13.08H16.8148V12.36Z" fill="white" />
                    <path d="M17.5556 12.36H18.2963V13.08H17.5556V12.36Z" fill="white" />
                    <path d="M18.2963 12.36H19.037V13.08H18.2963V12.36Z" fill="white" />
                    <path d="M19.037 12.36H19.7778V13.08H19.037V12.36Z" fill="white" />
                    <path d="M19.7778 12.36H20.5185V13.08H19.7778V12.36Z" fill="white" />
                    <path d="M20.5185 12.36H21.2593V13.08H20.5185V12.36Z" fill="white" />
                    <path d="M21.2593 12.36H22V13.08H21.2593V12.36Z" fill="white" />
                    <path d="M2 13.08H2.74074V13.8H2V13.08Z" fill="white" />
                    <path d="M2.74074 13.08H3.48148V13.8H2.74074V13.08Z" fill="white" />
                    <path d="M3.48148 13.08H4.22222V13.8H3.48148V13.08Z" fill="white" />
                    <path d="M4.22222 13.08H4.96296V13.8H4.22222V13.08Z" fill="white" />
                    <path d="M4.96296 13.08H5.7037V13.8H4.96296V13.08Z" fill="white" />
                    <path d="M5.7037 13.08H6.44444V13.8H5.7037V13.08Z" fill="white" />
                    <path d="M6.44444 13.08H7.18519V13.8H6.44444V13.08Z" fill="white" />
                    <path d="M7.18519 13.08H7.92593V13.8H7.18519V13.08Z" fill="white" />
                    <path d="M7.92593 13.08H8.66667V13.8H7.92593V13.08Z" fill="white" />
                    <path d="M8.66667 13.08H9.40741V13.8H8.66667V13.08Z" fill="white" />
                    <path d="M9.40741 13.08H10.1481V13.8H9.40741V13.08Z" fill="white" />
                    <path d="M10.1481 13.08H10.8889V13.8H10.1481V13.08Z" fill="white" />
                    <path d="M12.3704 13.08H13.1111V13.8H12.3704V13.08Z" fill="white" />
                    <path d="M13.1111 13.08H13.8519V13.8H13.1111V13.08Z" fill="white" />
                    <path d="M13.8519 13.08H14.5926V13.8H13.8519V13.08Z" fill="white" />
                    <path d="M14.5926 13.08H15.3333V13.8H14.5926V13.08Z" fill="white" />
                    <path d="M15.3333 13.08H16.0741V13.8H15.3333V13.08Z" fill="white" />
                    <path d="M16.0741 13.08H16.8148V13.8H16.0741V13.08Z" fill="white" />
                    <path d="M16.8148 13.08H17.5556V13.8H16.8148V13.08Z" fill="white" />
                    <path d="M17.5556 13.08H18.2963V13.8H17.5556V13.08Z" fill="white" />
                    <path d="M18.2963 13.08H19.037V13.8H18.2963V13.08Z" fill="white" />
                    <path d="M19.037 13.08H19.7778V13.8H19.037V13.08Z" fill="white" />
                    <path d="M19.7778 13.08H20.5185V13.8H19.7778V13.08Z" fill="white" />
                    <path d="M20.5185 13.08H21.2593V13.8H20.5185V13.08Z" fill="white" />
                    <path d="M21.2593 13.08H22V13.8H21.2593V13.08Z" fill="white" />
                    <path d="M2 13.8H2.74074V14.52H2V13.8Z" fill="white" />
                    <path d="M2.74074 13.8H3.48148V14.52H2.74074V13.8Z" fill="white" />
                    <path d="M3.48148 13.8H4.22222V14.52H3.48148V13.8Z" fill="white" />
                    <path d="M4.22222 13.8H4.96296V14.52H4.22222V13.8Z" fill="white" />
                    <path d="M4.96296 13.8H5.7037V14.52H4.96296V13.8Z" fill="white" />
                    <path d="M5.7037 13.8H6.44444V14.52H5.7037V13.8Z" fill="white" />
                    <path d="M6.44444 13.8H7.18519V14.52H6.44444V13.8Z" fill="white" />
                    <path d="M7.18519 13.8H7.92593V14.52H7.18519V13.8Z" fill="white" />
                    <path d="M7.92593 13.8H8.66667V14.52H7.92593V13.8Z" fill="white" />
                    <path d="M8.66667 13.8H9.40741V14.52H8.66667V13.8Z" fill="white" />
                    <path d="M9.40741 13.8H10.1481V14.52H9.40741V13.8Z" fill="white" />
                    <path d="M10.1481 13.8H10.8889V14.52H10.1481V13.8Z" fill="white" />
                    <path d="M12.3704 13.8H13.1111V14.52H12.3704V13.8Z" fill="white" />
                    <path d="M13.1111 13.8H13.8519V14.52H13.1111V13.8Z" fill="white" />
                    <path d="M13.8519 13.8H14.5926V14.52H13.8519V13.8Z" fill="white" />
                    <path d="M14.5926 13.8H15.3333V14.52H14.5926V13.8Z" fill="white" />
                    <path d="M15.3333 13.8H16.0741V14.52H15.3333V13.8Z" fill="white" />
                    <path d="M16.0741 13.8H16.8148V14.52H16.0741V13.8Z" fill="white" />
                    <path d="M16.8148 13.8H17.5556V14.52H16.8148V13.8Z" fill="white" />
                    <path d="M17.5556 13.8H18.2963V14.52H17.5556V13.8Z" fill="white" />
                    <path d="M18.2963 13.8H19.037V14.52H18.2963V13.8Z" fill="white" />
                    <path d="M19.037 13.8H19.7778V14.52H19.037V13.8Z" fill="white" />
                    <path d="M19.7778 13.8H20.5185V14.52H19.7778V13.8Z" fill="white" />
                    <path d="M20.5185 13.8H21.2593V14.52H20.5185V13.8Z" fill="white" />
                    <path d="M21.2593 13.8H22V14.52H21.2593V13.8Z" fill="white" />
                    <path d="M2 14.52H2.74074V15.24H2V14.52Z" fill="white" />
                    <path d="M2.74074 14.52H3.48148V15.24H2.74074V14.52Z" fill="white" />
                    <path d="M3.48148 14.52H4.22222V15.24H3.48148V14.52Z" fill="white" />
                    <path d="M4.22222 14.52H4.96296V15.24H4.22222V14.52Z" fill="white" />
                    <path d="M4.96296 14.52H5.7037V15.24H4.96296V14.52Z" fill="white" />
                    <path d="M5.7037 14.52H6.44444V15.24H5.7037V14.52Z" fill="white" />
                    <path d="M6.44444 14.52H7.18519V15.24H6.44444V14.52Z" fill="white" />
                    <path d="M7.18519 14.52H7.92593V15.24H7.18519V14.52Z" fill="white" />
                    <path d="M7.92593 14.52H8.66667V15.24H7.92593V14.52Z" fill="white" />
                    <path d="M8.66667 14.52H9.40741V15.24H8.66667V14.52Z" fill="white" />
                    <path d="M9.40741 14.52H10.1481V15.24H9.40741V14.52Z" fill="white" />
                    <path d="M10.1481 14.52H10.8889V15.24H10.1481V14.52Z" fill="white" />
                    <path d="M12.3704 14.52H13.1111V15.24H12.3704V14.52Z" fill="white" />
                    <path d="M13.1111 14.52H13.8519V15.24H13.1111V14.52Z" fill="white" />
                    <path d="M13.8519 14.52H14.5926V15.24H13.8519V14.52Z" fill="white" />
                    <path d="M14.5926 14.52H15.3333V15.24H14.5926V14.52Z" fill="white" />
                    <path d="M15.3333 14.52H16.0741V15.24H15.3333V14.52Z" fill="white" />
                    <path d="M16.0741 14.52H16.8148V15.24H16.0741V14.52Z" fill="white" />
                    <path d="M16.8148 14.52H17.5556V15.24H16.8148V14.52Z" fill="white" />
                    <path d="M17.5556 14.52H18.2963V15.24H17.5556V14.52Z" fill="white" />
                    <path d="M18.2963 14.52H19.037V15.24H18.2963V14.52Z" fill="white" />
                    <path d="M19.037 14.52H19.7778V15.24H19.037V14.52Z" fill="white" />
                    <path d="M19.7778 14.52H20.5185V15.24H19.7778V14.52Z" fill="white" />
                    <path d="M20.5185 14.52H21.2593V15.24H20.5185V14.52Z" fill="white" />
                    <path d="M21.2593 14.52H22V15.24H21.2593V14.52Z" fill="white" />
                    <path d="M2 15.24H2.74074V15.96H2V15.24Z" fill="white" />
                    <path d="M2.74074 15.24H3.48148V15.96H2.74074V15.24Z" fill="white" />
                    <path d="M3.48148 15.24H4.22222V15.96H3.48148V15.24Z" fill="white" />
                    <path d="M4.22222 15.24H4.96296V15.96H4.22222V15.24Z" fill="white" />
                    <path d="M4.96296 15.24H5.7037V15.96H4.96296V15.24Z" fill="white" />
                    <path d="M5.7037 15.24H6.44444V15.96H5.7037V15.24Z" fill="white" />
                    <path d="M6.44444 15.24H7.18519V15.96H6.44444V15.24Z" fill="white" />
                    <path d="M7.18519 15.24H7.92593V15.96H7.18519V15.24Z" fill="white" />
                    <path d="M7.92593 15.24H8.66667V15.96H7.92593V15.24Z" fill="white" />
                    <path d="M8.66667 15.24H9.40741V15.96H8.66667V15.24Z" fill="white" />
                    <path d="M9.40741 15.24H10.1481V15.96H9.40741V15.24Z" fill="white" />
                    <path d="M10.1481 15.24H10.8889V15.96H10.1481V15.24Z" fill="white" />
                    <path d="M12.3704 15.24H13.1111V15.96H12.3704V15.24Z" fill="white" />
                    <path d="M13.1111 15.24H13.8519V15.96H13.1111V15.24Z" fill="white" />
                    <path d="M13.8519 15.24H14.5926V15.96H13.8519V15.24Z" fill="white" />
                    <path d="M14.5926 15.24H15.3333V15.96H14.5926V15.24Z" fill="white" />
                    <path d="M15.3333 15.24H16.0741V15.96H15.3333V15.24Z" fill="white" />
                    <path d="M16.0741 15.24H16.8148V15.96H16.0741V15.24Z" fill="white" />
                    <path d="M16.8148 15.24H17.5556V15.96H16.8148V15.24Z" fill="white" />
                    <path d="M17.5556 15.24H18.2963V15.96H17.5556V15.24Z" fill="white" />
                    <path d="M18.2963 15.24H19.037V15.96H18.2963V15.24Z" fill="white" />
                    <path d="M19.037 15.24H19.7778V15.96H19.037V15.24Z" fill="white" />
                    <path d="M19.7778 15.24H20.5185V15.96H19.7778V15.24Z" fill="white" />
                    <path d="M20.5185 15.24H21.2593V15.96H20.5185V15.24Z" fill="white" />
                    <path d="M21.2593 15.24H22V15.96H21.2593V15.24Z" fill="white" />
                    <path d="M2 15.96H2.74074V16.68H2V15.96Z" fill="white" />
                    <path d="M2.74074 15.96H3.48148V16.68H2.74074V15.96Z" fill="white" />
                    <path d="M3.48148 15.96H4.22222V16.68H3.48148V15.96Z" fill="white" />
                    <path d="M4.22222 15.96H4.96296V16.68H4.22222V15.96Z" fill="white" />
                    <path d="M4.96296 15.96H5.7037V16.68H4.96296V15.96Z" fill="white" />
                    <path d="M5.7037 15.96H6.44444V16.68H5.7037V15.96Z" fill="white" />
                    <path d="M6.44444 15.96H7.18519V16.68H6.44444V15.96Z" fill="white" />
                    <path d="M7.18519 15.96H7.92593V16.68H7.18519V15.96Z" fill="white" />
                    <path d="M7.92593 15.96H8.66667V16.68H7.92593V15.96Z" fill="white" />
                    <path d="M8.66667 15.96H9.40741V16.68H8.66667V15.96Z" fill="white" />
                    <path d="M9.40741 15.96H10.1481V16.68H9.40741V15.96Z" fill="white" />
                    <path d="M10.1481 15.96H10.8889V16.68H10.1481V15.96Z" fill="white" />
                    <path d="M12.3704 15.96H13.1111V16.68H12.3704V15.96Z" fill="white" />
                    <path d="M13.1111 15.96H13.8519V16.68H13.1111V15.96Z" fill="white" />
                    <path d="M13.8519 15.96H14.5926V16.68H13.8519V15.96Z" fill="white" />
                    <path d="M14.5926 15.96H15.3333V16.68H14.5926V15.96Z" fill="white" />
                    <path d="M15.3333 15.96H16.0741V16.68H15.3333V15.96Z" fill="white" />
                    <path d="M16.0741 15.96H16.8148V16.68H16.0741V15.96Z" fill="white" />
                    <path d="M16.8148 15.96H17.5556V16.68H16.8148V15.96Z" fill="white" />
                    <path d="M17.5556 15.96H18.2963V16.68H17.5556V15.96Z" fill="white" />
                    <path d="M18.2963 15.96H19.037V16.68H18.2963V15.96Z" fill="white" />
                    <path d="M19.037 15.96H19.7778V16.68H19.037V15.96Z" fill="white" />
                    <path d="M19.7778 15.96H20.5185V16.68H19.7778V15.96Z" fill="white" />
                    <path d="M20.5185 15.96H21.2593V16.68H20.5185V15.96Z" fill="white" />
                    <path d="M21.2593 15.96H22V16.68H21.2593V15.96Z" fill="white" />
                    <path d="M2 16.68H2.74074V17.4H2V16.68Z" fill="white" />
                    <path d="M2.74074 16.68H3.48148V17.4H2.74074V16.68Z" fill="white" />
                    <path d="M3.48148 16.68H4.22222V17.4H3.48148V16.68Z" fill="white" />
                    <path d="M4.22222 16.68H4.96296V17.4H4.22222V16.68Z" fill="white" />
                    <path d="M4.96296 16.68H5.7037V17.4H4.96296V16.68Z" fill="white" />
                    <path d="M5.7037 16.68H6.44444V17.4H5.7037V16.68Z" fill="white" />
                    <path d="M6.44444 16.68H7.18519V17.4H6.44444V16.68Z" fill="white" />
                    <path d="M7.18519 16.68H7.92593V17.4H7.18519V16.68Z" fill="white" />
                    <path d="M7.92593 16.68H8.66667V17.4H7.92593V16.68Z" fill="white" />
                    <path d="M8.66667 16.68H9.40741V17.4H8.66667V16.68Z" fill="white" />
                    <path d="M9.40741 16.68H10.1481V17.4H9.40741V16.68Z" fill="white" />
                    <path d="M10.1481 16.68H10.8889V17.4H10.1481V16.68Z" fill="white" />
                    <path d="M12.3704 16.68H13.1111V17.4H12.3704V16.68Z" fill="white" />
                    <path d="M13.1111 16.68H13.8519V17.4H13.1111V16.68Z" fill="white" />
                    <path d="M13.8519 16.68H14.5926V17.4H13.8519V16.68Z" fill="white" />
                    <path d="M14.5926 16.68H15.3333V17.4H14.5926V16.68Z" fill="white" />
                    <path d="M15.3333 16.68H16.0741V17.4H15.3333V16.68Z" fill="white" />
                    <path d="M16.0741 16.68H16.8148V17.4H16.0741V16.68Z" fill="white" />
                    <path d="M16.8148 16.68H17.5556V17.4H16.8148V16.68Z" fill="white" />
                    <path d="M17.5556 16.68H18.2963V17.4H17.5556V16.68Z" fill="white" />
                    <path d="M18.2963 16.68H19.037V17.4H18.2963V16.68Z" fill="white" />
                    <path d="M19.037 16.68H19.7778V17.4H19.037V16.68Z" fill="white" />
                    <path d="M19.7778 16.68H20.5185V17.4H19.7778V16.68Z" fill="white" />
                    <path d="M20.5185 16.68H21.2593V17.4H20.5185V16.68Z" fill="white" />
                    <path d="M21.2593 16.68H22V17.4H21.2593V16.68Z" fill="white" />
                    <path d="M2 17.4H2.74074V18.12H2V17.4Z" fill="white" />
                    <path d="M2.74074 17.4H3.48148V18.12H2.74074V17.4Z" fill="white" />
                    <path d="M3.48148 17.4H4.22222V18.12H3.48148V17.4Z" fill="white" />
                    <path d="M4.22222 17.4H4.96296V18.12H4.22222V17.4Z" fill="white" />
                    <path d="M4.96296 17.4H5.7037V18.12H4.96296V17.4Z" fill="white" />
                    <path d="M5.7037 17.4H6.44444V18.12H5.7037V17.4Z" fill="white" />
                    <path d="M6.44444 17.4H7.18519V18.12H6.44444V17.4Z" fill="white" />
                    <path d="M7.18519 17.4H7.92593V18.12H7.18519V17.4Z" fill="white" />
                    <path d="M7.92593 17.4H8.66667V18.12H7.92593V17.4Z" fill="white" />
                    <path d="M8.66667 17.4H9.40741V18.12H8.66667V17.4Z" fill="white" />
                    <path d="M9.40741 17.4H10.1481V18.12H9.40741V17.4Z" fill="white" />
                    <path d="M10.1481 17.4H10.8889V18.12H10.1481V17.4Z" fill="white" />
                    <path d="M12.3704 17.4H13.1111V18.12H12.3704V17.4Z" fill="white" />
                    <path d="M13.1111 17.4H13.8519V18.12H13.1111V17.4Z" fill="white" />
                    <path d="M13.8519 17.4H14.5926V18.12H13.8519V17.4Z" fill="white" />
                    <path d="M14.5926 17.4H15.3333V18.12H14.5926V17.4Z" fill="white" />
                    <path d="M15.3333 17.4H16.0741V18.12H15.3333V17.4Z" fill="white" />
                    <path d="M16.0741 17.4H16.8148V18.12H16.0741V17.4Z" fill="white" />
                    <path d="M16.8148 17.4H17.5556V18.12H16.8148V17.4Z" fill="white" />
                    <path d="M17.5556 17.4H18.2963V18.12H17.5556V17.4Z" fill="white" />
                    <path d="M18.2963 17.4H19.037V18.12H18.2963V17.4Z" fill="white" />
                    <path d="M19.037 17.4H19.7778V18.12H19.037V17.4Z" fill="white" />
                    <path d="M19.7778 17.4H20.5185V18.12H19.7778V17.4Z" fill="white" />
                    <path d="M20.5185 17.4H21.2593V18.12H20.5185V17.4Z" fill="white" />
                    <path d="M21.2593 17.4H22V18.12H21.2593V17.4Z" fill="white" />
                    <path d="M2.74074 18.12H3.48148V18.84H2.74074V18.12Z" fill="white" />
                    <path d="M3.48148 18.12H4.22222V18.84H3.48148V18.12Z" fill="white" />
                    <path d="M4.22222 18.12H4.96296V18.84H4.22222V18.12Z" fill="white" />
                    <path d="M4.96296 18.12H5.7037V18.84H4.96296V18.12Z" fill="white" />
                    <path d="M5.7037 18.12H6.44444V18.84H5.7037V18.12Z" fill="white" />
                    <path d="M6.44444 18.12H7.18519V18.84H6.44444V18.12Z" fill="white" />
                    <path d="M7.18519 18.12H7.92593V18.84H7.18519V18.12Z" fill="white" />
                    <path d="M7.92593 18.12H8.66667V18.84H7.92593V18.12Z" fill="white" />
                    <path d="M8.66667 18.12H9.40741V18.84H8.66667V18.12Z" fill="white" />
                    <path d="M9.40741 18.12H10.1481V18.84H9.40741V18.12Z" fill="white" />
                    <path d="M10.1481 18.12H10.8889V18.84H10.1481V18.12Z" fill="white" />
                    <path d="M12.3704 18.12H13.1111V18.84H12.3704V18.12Z" fill="white" />
                    <path d="M13.1111 18.12H13.8519V18.84H13.1111V18.12Z" fill="white" />
                    <path d="M13.8519 18.12H14.5926V18.84H13.8519V18.12Z" fill="white" />
                    <path d="M14.5926 18.12H15.3333V18.84H14.5926V18.12Z" fill="white" />
                    <path d="M15.3333 18.12H16.0741V18.84H15.3333V18.12Z" fill="white" />
                    <path d="M16.0741 18.12H16.8148V18.84H16.0741V18.12Z" fill="white" />
                    <path d="M16.8148 18.12H17.5556V18.84H16.8148V18.12Z" fill="white" />
                    <path d="M17.5556 18.12H18.2963V18.84H17.5556V18.12Z" fill="white" />
                    <path d="M18.2963 18.12H19.037V18.84H18.2963V18.12Z" fill="white" />
                    <path d="M19.037 18.12H19.7778V18.84H19.037V18.12Z" fill="white" />
                    <path d="M19.7778 18.12H20.5185V18.84H19.7778V18.12Z" fill="white" />
                    <path d="M20.5185 18.12H21.2593V18.84H20.5185V18.12Z" fill="white" />
                    <path d="M21.2593 18.12H22V18.84H21.2593V18.12Z" fill="white" />
                    <path d="M8.66667 18.84H9.40741V19.56H8.66667V18.84Z" fill="white" />
                    <path d="M9.40741 18.84H10.1481V19.56H9.40741V18.84Z" fill="white" />
                    <path d="M10.1481 18.84H10.8889V19.56H10.1481V18.84Z" fill="white" />
                    <path d="M12.3704 18.84H13.1111V19.56H12.3704V18.84Z" fill="white" />
                    <path d="M13.1111 18.84H13.8519V19.56H13.1111V18.84Z" fill="white" />
                    <path d="M13.8519 18.84H14.5926V19.56H13.8519V18.84Z" fill="white" />
                    <path d="M14.5926 18.84H15.3333V19.56H14.5926V18.84Z" fill="white" />
                    <path d="M15.3333 18.84H16.0741V19.56H15.3333V18.84Z" fill="white" />
                    <path d="M16.0741 18.84H16.8148V19.56H16.0741V18.84Z" fill="white" />
                    <path d="M16.8148 18.84H17.5556V19.56H16.8148V18.84Z" fill="white" />
                    <path d="M17.5556 18.84H18.2963V19.56H17.5556V18.84Z" fill="white" />
                    <path d="M18.2963 18.84H19.037V19.56H18.2963V18.84Z" fill="white" />
                    <path d="M19.037 18.84H19.7778V19.56H19.037V18.84Z" fill="white" />
                    <path d="M19.7778 18.84H20.5185V19.56H19.7778V18.84Z" fill="white" />
                    <path d="M20.5185 18.84H21.2593V19.56H20.5185V18.84Z" fill="white" />
                    <path d="M21.2593 18.84H22V19.56H21.2593V18.84Z" fill="white" />
                    <path d="M14.5926 19.56H15.3333V20.28H14.5926V19.56Z" fill="white" />
                    <path d="M15.3333 19.56H16.0741V20.28H15.3333V19.56Z" fill="white" />
                    <path d="M16.0741 19.56H16.8148V20.28H16.0741V19.56Z" fill="white" />
                    <path d="M16.8148 19.56H17.5556V20.28H16.8148V19.56Z" fill="white" />
                    <path d="M17.5556 19.56H18.2963V20.28H17.5556V19.56Z" fill="white" />
                    <path d="M18.2963 19.56H19.037V20.28H18.2963V19.56Z" fill="white" />
                    <path d="M19.037 19.56H19.7778V20.28H19.037V19.56Z" fill="white" />
                    <path d="M19.7778 19.56H20.5185V20.28H19.7778V19.56Z" fill="white" />
                    <path d="M20.5185 19.56H21.2593V20.28H20.5185V19.56Z" fill="white" />
                    <path d="M21.2593 19.56H22V20.28H21.2593V19.56Z" fill="white" />
                    <path d="M20.5185 20.28H21.2593V21H20.5185V20.28Z" fill="white" />
                    <path d="M21.2593 20.28H22V21H21.2593V20.28Z" fill="white" />
                </g>
            </g>
        </svg>

    );
}