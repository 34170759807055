import { fonts } from "../config/const";

export const loadFonts = async () => {
    const fontPromises = fonts.map((font) => {
      const fontFace = new FontFace(font.family, `url(${font.url})`);
      document.fonts.add(fontFace);
      return fontFace.load();
    });
  
    await Promise.all(fontPromises);
  };
  
export const loadImages = (images) => {
    const promises = images.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    });
    return Promise.all(promises);
  }