import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React, { useState, useEffect, Suspense } from "react";
import { LanguageProvider } from './context/languageContext';
import { loadFonts, loadImages } from './utils/loading';
import { photo_data } from './config/const';
import { RingLoader } from "react-spinners";
// import Home from './page/Home';

const Header = React.lazy(() => import('./components/Header'));
const Footer = React.lazy(() => import('./components/Footer'));
const Home = React.lazy(() => import('./page/Home'));
const Privacy = React.lazy(() => import('./page/Privacy'));

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadImages(photo_data)
      .then(() => {
        loadFonts()
          .then(() => {
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Loading Font Error: ", error);
            setIsLoading(true);
          });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Loading Image Error: ", error);
        setIsLoading(true);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-[100vh]">
        <RingLoader
          size={120} // Size of the loader
          color={"#20A75C"} // Color of the loader
          loading={isLoading} // Pass the loading state
        />
      </div>
    )
  }

  return (
    <Router>
      {!isLoading && (
        <Suspense fallback={<div>Loading...</div>}>
          <LanguageProvider>
            <Header />
            <main>
              <Routes>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<Home />} />
                <Route path="/privacy" element={<Privacy />} />
              </Routes>
            </main>
            <Footer />
          </LanguageProvider>
        </Suspense>
      )}
    </Router>
  )
}

export default App;
