import WindowsIcon from "../assets/img/shape/window"
import LinuxIcon from "../assets/img/shape/linux"
import MacIcon from "../assets/img/shape/mac"
import ChesetIcon from "../assets/img/shape/chest"
import ClockIcon from "../assets/img/shape/clock"
import CoinIcon from "../assets/img/shape/coin"
import SwordIcon from "../assets/img/shape/sword"

export const photo_data = [
    '/image/background/contact.webp',
    '/image/background/diamonds-left.webp',
    '/image/background/diamonds-left-mobile.webp',
    '/image/background/diamonds-right.webp',
    '/image/background/diamonds-right-mobile.webp',
    '/image/background/explore.webp',
    '/image/background/hunt.webp',
    '/image/background/market.webp',
    '/image/background/market-mobile.webp',
    '/image/background/prologue.webp',
    '/image/background/prologue-mobile.webp',
    '/image/background/season-1.webp',
    '/image/background/season-2.webp',
    '/image/background/season-3.webp',
    '/image/background/season-4.webp',
    '/image/background/sxp.webp',
    '/image/background/sxp-mobile.webp',
    '/image/icon/arrow.svg',
    '/image/icon/ArrowSide.svg',
    '/image/icon/Chest.svg',
    '/image/icon/close.svg',
    '/image/icon/Community.svg',
    '/image/icon/eye.svg',
    '/image/icon/Play.svg',
    '/image/icon/logo.webp',
    '/image/icon/solar.webp',
    '/image/icon/tymt.webp',
]

export const video_data = {
    explore: {
        title: 'District 53 - Trailer',
        url: 'https://www.youtube.com/embed/3wti8fb9OuY?start=9'
    },
}

export const fonts = [
    { family: 'Pixeboy', url: '/font/pixeboy.woff2' },
    { family: 'Fifaks', url: '/font/Fifaks10Dev1.woff2' },
    { family: 'Golos', url: '/font/GolosText.woff2' },
]

export const social_links = {
    telegram: {
        link: 'https://t.me/district_53'
    },
    x: {
        link: 'https://x.com/D53_Metaverse'
    },
    discord: {
        link: 'https://discord.gg/district53'
    },
    tymt: {
        link: 'https://tymt.com'
    }
}

export const install_options = [
    {
        title: "linux-title",
        icon: <LinuxIcon width={'25px'} height={'25px'} />,
    },
    {
        title: "windows-title",
        icon: <WindowsIcon width={'25px'} height={'25px'} />,
    },
    {
        title: "mac-title",
        icon: <MacIcon width={'25px'} height={'25px'} />,
    },
]

export const market_data = [
    {
        title: "market-resource",
        icon: <ChesetIcon width={'25px'} height={'25px'} />,
    },
    {
        title: "market-craft",
        icon: <ClockIcon width={'25px'} height={'25px'} />,
    },
    {
        title: "market-food",
        icon: <SwordIcon width={'25px'} height={'25px'} />,
    },
    {
        title: "market-sxp",
        icon: <CoinIcon width={'25px'} height={'25px'} />,
    },
]

export const join_hunt_data = [
    {
        title: "hunt-content-join",
        icon: '/image/icon/ArrowSide.svg',
        width: 'full',
        href: 'https://discord.gg/district53'
    },
    {
        title: "hunt-content-invite",
        icon: '/image/icon/Community.svg',
        width: '[332px]',
        href: ''
    },
    {
        title: "hunt-content-find",
        icon: '/image/icon/Chest.svg',
        width: '[292px]',
        href: '',
        last: "hunt-sell",
    }
]

export const email_content_html = (username, nickName, email) => `
            <html>
                <head>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            line-height: 1.6;
                            background-color: #f4f4f4;
                            color: #333;
                        }
                        .container {
                            width: 100%;
                            max-width: 600px;
                            margin: 20px auto;
                            padding: 20px;
                            border: 1px solid #ddd;
                            border-radius: 10px;
                            background-color: #fff;
                            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                            position: relative;
                        }
                        .header {
                            text-align: center;
                            margin-bottom: 20px;
                        }
                        .header h1 {
                            margin: 0;
                            color: #444;
                            font-size: 24px;
                        }
                        .forward-button {
                            position: absolute;
                            top: 20px;
                            right: 20px;
                            background-color: #007bff;
                            color: #fff;
                            border: none;
                            border-radius: 5px;
                            padding: 8px 12px;
                            text-decoration: none;
                            font-size: 14px;
                        }
                        .content {
                            margin-top: 20px;
                        }
                        .content p {
                            margin: 10px 0;
                            padding: 10px;
                            border-bottom: 1px solid #eee;
                        }
                        .content p:last-child {
                            border-bottom: none;
                        }
                        .content p strong {
                            color: #555;
                        }
                        .footer {
                            text-align: center;
                            margin-top: 20px;
                            font-size: 12px;
                            color: #aaa;
                        }
                    </style>
                </head>
                <body>
                    <div class="container">
                        <div class="header">
                            <h1>🚀 New Join Request!</h1>
                        </div>
                        <div class="content">
                            <p><strong>Full Name:</strong> ${username}</p>
                            <p><strong>Nickname:</strong> ${nickName}</p>
                            <p><strong>Email Address:</strong> ${email}</p>
                        </div>
                    </div>
                </body>
            </html>`