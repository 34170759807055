export default function CoinIcon({ width, height, color }) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons">
                <g id="Vector">
                    <path d="M15.4884 3H15.9535V3.47368H15.4884V3Z" fill="white" />
                    <path d="M15.9535 3H16.4186V3.47368H15.9535V3Z" fill="white" />
                    <path d="M16.4186 3H16.8837V3.47368H16.4186V3Z" fill="white" />
                    <path d="M12.6977 3.47368H13.1628V3.94737H12.6977V3.47368Z" fill="white" />
                    <path d="M13.1628 3.47368H13.6279V3.94737H13.1628V3.47368Z" fill="white" />
                    <path d="M13.6279 3.47368H14.093V3.94737H13.6279V3.47368Z" fill="white" />
                    <path d="M14.093 3.47368H14.5581V3.94737H14.093V3.47368Z" fill="white" />
                    <path d="M14.5581 3.47368H15.0233V3.94737H14.5581V3.47368Z" fill="white" />
                    <path d="M15.0233 3.47368H15.4884V3.94737H15.0233V3.47368Z" fill="white" />
                    <path d="M15.4884 3.47368H15.9535V3.94737H15.4884V3.47368Z" fill="white" />
                    <path d="M15.9535 3.47368H16.4186V3.94737H15.9535V3.47368Z" fill="white" />
                    <path d="M16.4186 3.47368H16.8837V3.94737H16.4186V3.47368Z" fill="white" />
                    <path d="M16.8837 3.47368H17.3488V3.94737H16.8837V3.47368Z" fill="white" />
                    <path d="M17.3488 3.47368H17.814V3.94737H17.3488V3.47368Z" fill="white" />
                    <path d="M17.814 3.47368H18.2791V3.94737H17.814V3.47368Z" fill="white" />
                    <path d="M18.2791 3.47368H18.7442V3.94737H18.2791V3.47368Z" fill="white" />
                    <path d="M18.7442 3.47368H19.2093V3.94737H18.7442V3.47368Z" fill="white" />
                    <path d="M19.2093 3.47368H19.6744V3.94737H19.2093V3.47368Z" fill="white" />
                    <path d="M11.7674 3.94737H12.2326V4.42105H11.7674V3.94737Z" fill="white" />
                    <path d="M12.2326 3.94737H12.6977V4.42105H12.2326V3.94737Z" fill="white" />
                    <path d="M12.6977 3.94737H13.1628V4.42105H12.6977V3.94737Z" fill="white" />
                    <path d="M13.1628 3.94737H13.6279V4.42105H13.1628V3.94737Z" fill="white" />
                    <path d="M13.6279 3.94737H14.093V4.42105H13.6279V3.94737Z" fill="white" />
                    <path d="M14.093 3.94737H14.5581V4.42105H14.093V3.94737Z" fill="white" />
                    <path d="M14.5581 3.94737H15.0233V4.42105H14.5581V3.94737Z" fill="white" />
                    <path d="M15.0233 3.94737H15.4884V4.42105H15.0233V3.94737Z" fill="white" />
                    <path d="M15.4884 3.94737H15.9535V4.42105H15.4884V3.94737Z" fill="white" />
                    <path d="M15.9535 3.94737H16.4186V4.42105H15.9535V3.94737Z" fill="white" />
                    <path d="M16.4186 3.94737H16.8837V4.42105H16.4186V3.94737Z" fill="white" />
                    <path d="M16.8837 3.94737H17.3488V4.42105H16.8837V3.94737Z" fill="white" />
                    <path d="M17.3488 3.94737H17.814V4.42105H17.3488V3.94737Z" fill="white" />
                    <path d="M17.814 3.94737H18.2791V4.42105H17.814V3.94737Z" fill="white" />
                    <path d="M18.2791 3.94737H18.7442V4.42105H18.2791V3.94737Z" fill="white" />
                    <path d="M18.7442 3.94737H19.2093V4.42105H18.7442V3.94737Z" fill="white" />
                    <path d="M19.2093 3.94737H19.6744V4.42105H19.2093V3.94737Z" fill="white" />
                    <path d="M19.6744 3.94737H20.1395V4.42105H19.6744V3.94737Z" fill="white" />
                    <path d="M20.1395 3.94737H20.6047V4.42105H20.1395V3.94737Z" fill="white" />
                    <path d="M10.8372 4.42105H11.3023V4.89474H10.8372V4.42105Z" fill="white" />
                    <path d="M11.3023 4.42105H11.7674V4.89474H11.3023V4.42105Z" fill="white" />
                    <path d="M11.7674 4.42105H12.2326V4.89474H11.7674V4.42105Z" fill="white" />
                    <path d="M12.2326 4.42105H12.6977V4.89474H12.2326V4.42105Z" fill="white" />
                    <path d="M12.6977 4.42105H13.1628V4.89474H12.6977V4.42105Z" fill="white" />
                    <path d="M13.1628 4.42105H13.6279V4.89474H13.1628V4.42105Z" fill="white" />
                    <path d="M13.6279 4.42105H14.093V4.89474H13.6279V4.42105Z" fill="white" />
                    <path d="M14.093 4.42105H14.5581V4.89474H14.093V4.42105Z" fill="white" />
                    <path d="M14.5581 4.42105H15.0233V4.89474H14.5581V4.42105Z" fill="white" />
                    <path d="M15.0233 4.42105H15.4884V4.89474H15.0233V4.42105Z" fill="white" />
                    <path d="M15.4884 4.42105H15.9535V4.89474H15.4884V4.42105Z" fill="white" />
                    <path d="M15.9535 4.42105H16.4186V4.89474H15.9535V4.42105Z" fill="white" />
                    <path d="M16.4186 4.42105H16.8837V4.89474H16.4186V4.42105Z" fill="white" />
                    <path d="M16.8837 4.42105H17.3488V4.89474H16.8837V4.42105Z" fill="white" />
                    <path d="M17.3488 4.42105H17.814V4.89474H17.3488V4.42105Z" fill="white" />
                    <path d="M17.814 4.42105H18.2791V4.89474H17.814V4.42105Z" fill="white" />
                    <path d="M18.2791 4.42105H18.7442V4.89474H18.2791V4.42105Z" fill="white" />
                    <path d="M18.7442 4.42105H19.2093V4.89474H18.7442V4.42105Z" fill="white" />
                    <path d="M19.2093 4.42105H19.6744V4.89474H19.2093V4.42105Z" fill="white" />
                    <path d="M19.6744 4.42105H20.1395V4.89474H19.6744V4.42105Z" fill="white" />
                    <path d="M20.1395 4.42105H20.6047V4.89474H20.1395V4.42105Z" fill="white" />
                    <path d="M20.6047 4.42105H21.0698V4.89474H20.6047V4.42105Z" fill="white" />
                    <path d="M10.8372 4.89474H11.3023V5.36842H10.8372V4.89474Z" fill="white" />
                    <path d="M11.3023 4.89474H11.7674V5.36842H11.3023V4.89474Z" fill="white" />
                    <path d="M11.7674 4.89474H12.2326V5.36842H11.7674V4.89474Z" fill="white" />
                    <path d="M12.2326 4.89474H12.6977V5.36842H12.2326V4.89474Z" fill="white" />
                    <path d="M12.6977 4.89474H13.1628V5.36842H12.6977V4.89474Z" fill="white" />
                    <path d="M13.1628 4.89474H13.6279V5.36842H13.1628V4.89474Z" fill="white" />
                    <path d="M13.6279 4.89474H14.093V5.36842H13.6279V4.89474Z" fill="white" />
                    <path d="M14.093 4.89474H14.5581V5.36842H14.093V4.89474Z" fill="white" />
                    <path d="M14.5581 4.89474H15.0233V5.36842H14.5581V4.89474Z" fill="white" />
                    <path d="M15.0233 4.89474H15.4884V5.36842H15.0233V4.89474Z" fill="white" />
                    <path d="M15.4884 4.89474H15.9535V5.36842H15.4884V4.89474Z" fill="white" />
                    <path d="M15.9535 4.89474H16.4186V5.36842H15.9535V4.89474Z" fill="white" />
                    <path d="M16.4186 4.89474H16.8837V5.36842H16.4186V4.89474Z" fill="white" />
                    <path d="M16.8837 4.89474H17.3488V5.36842H16.8837V4.89474Z" fill="white" />
                    <path d="M17.3488 4.89474H17.814V5.36842H17.3488V4.89474Z" fill="white" />
                    <path d="M17.814 4.89474H18.2791V5.36842H17.814V4.89474Z" fill="white" />
                    <path d="M18.2791 4.89474H18.7442V5.36842H18.2791V4.89474Z" fill="white" />
                    <path d="M18.7442 4.89474H19.2093V5.36842H18.7442V4.89474Z" fill="white" />
                    <path d="M19.2093 4.89474H19.6744V5.36842H19.2093V4.89474Z" fill="white" />
                    <path d="M19.6744 4.89474H20.1395V5.36842H19.6744V4.89474Z" fill="white" />
                    <path d="M20.1395 4.89474H20.6047V5.36842H20.1395V4.89474Z" fill="white" />
                    <path d="M20.6047 4.89474H21.0698V5.36842H20.6047V4.89474Z" fill="white" />
                    <path d="M21.0698 4.89474H21.5349V5.36842H21.0698V4.89474Z" fill="white" />
                    <path d="M10.3721 5.36842H10.8372V5.84211H10.3721V5.36842Z" fill="white" />
                    <path d="M10.8372 5.36842H11.3023V5.84211H10.8372V5.36842Z" fill="white" />
                    <path d="M11.3023 5.36842H11.7674V5.84211H11.3023V5.36842Z" fill="white" />
                    <path d="M11.7674 5.36842H12.2326V5.84211H11.7674V5.36842Z" fill="white" />
                    <path d="M12.2326 5.36842H12.6977V5.84211H12.2326V5.36842Z" fill="white" />
                    <path d="M12.6977 5.36842H13.1628V5.84211H12.6977V5.36842Z" fill="white" />
                    <path d="M13.1628 5.36842H13.6279V5.84211H13.1628V5.36842Z" fill="white" />
                    <path d="M13.6279 5.36842H14.093V5.84211H13.6279V5.36842Z" fill="white" />
                    <path d="M14.093 5.36842H14.5581V5.84211H14.093V5.36842Z" fill="white" />
                    <path d="M14.5581 5.36842H15.0233V5.84211H14.5581V5.36842Z" fill="white" />
                    <path d="M15.0233 5.36842H15.4884V5.84211H15.0233V5.36842Z" fill="white" />
                    <path d="M15.4884 5.36842H15.9535V5.84211H15.4884V5.36842Z" fill="white" />
                    <path d="M15.9535 5.36842H16.4186V5.84211H15.9535V5.36842Z" fill="white" />
                    <path d="M16.4186 5.36842H16.8837V5.84211H16.4186V5.36842Z" fill="white" />
                    <path d="M16.8837 5.36842H17.3488V5.84211H16.8837V5.36842Z" fill="white" />
                    <path d="M17.3488 5.36842H17.814V5.84211H17.3488V5.36842Z" fill="white" />
                    <path d="M17.814 5.36842H18.2791V5.84211H17.814V5.36842Z" fill="white" />
                    <path d="M18.2791 5.36842H18.7442V5.84211H18.2791V5.36842Z" fill="white" />
                    <path d="M18.7442 5.36842H19.2093V5.84211H18.7442V5.36842Z" fill="white" />
                    <path d="M19.2093 5.36842H19.6744V5.84211H19.2093V5.36842Z" fill="white" />
                    <path d="M19.6744 5.36842H20.1395V5.84211H19.6744V5.36842Z" fill="white" />
                    <path d="M20.1395 5.36842H20.6047V5.84211H20.1395V5.36842Z" fill="white" />
                    <path d="M20.6047 5.36842H21.0698V5.84211H20.6047V5.36842Z" fill="white" />
                    <path d="M21.0698 5.36842H21.5349V5.84211H21.0698V5.36842Z" fill="white" />
                    <path d="M21.5349 5.36842H22V5.84211H21.5349V5.36842Z" fill="white" />
                    <path d="M10.3721 5.84211H10.8372V6.31579H10.3721V5.84211Z" fill="white" />
                    <path d="M10.8372 5.84211H11.3023V6.31579H10.8372V5.84211Z" fill="white" />
                    <path d="M11.3023 5.84211H11.7674V6.31579H11.3023V5.84211Z" fill="white" />
                    <path d="M11.7674 5.84211H12.2326V6.31579H11.7674V5.84211Z" fill="white" />
                    <path d="M12.2326 5.84211H12.6977V6.31579H12.2326V5.84211Z" fill="white" />
                    <path d="M12.6977 5.84211H13.1628V6.31579H12.6977V5.84211Z" fill="white" />
                    <path d="M13.1628 5.84211H13.6279V6.31579H13.1628V5.84211Z" fill="white" />
                    <path d="M13.6279 5.84211H14.093V6.31579H13.6279V5.84211Z" fill="white" />
                    <path d="M14.093 5.84211H14.5581V6.31579H14.093V5.84211Z" fill="white" />
                    <path d="M14.5581 5.84211H15.0233V6.31579H14.5581V5.84211Z" fill="white" />
                    <path d="M15.0233 5.84211H15.4884V6.31579H15.0233V5.84211Z" fill="white" />
                    <path d="M15.4884 5.84211H15.9535V6.31579H15.4884V5.84211Z" fill="white" />
                    <path d="M15.9535 5.84211H16.4186V6.31579H15.9535V5.84211Z" fill="white" />
                    <path d="M16.4186 5.84211H16.8837V6.31579H16.4186V5.84211Z" fill="white" />
                    <path d="M16.8837 5.84211H17.3488V6.31579H16.8837V5.84211Z" fill="white" />
                    <path d="M17.3488 5.84211H17.814V6.31579H17.3488V5.84211Z" fill="white" />
                    <path d="M17.814 5.84211H18.2791V6.31579H17.814V5.84211Z" fill="white" />
                    <path d="M18.2791 5.84211H18.7442V6.31579H18.2791V5.84211Z" fill="white" />
                    <path d="M18.7442 5.84211H19.2093V6.31579H18.7442V5.84211Z" fill="white" />
                    <path d="M19.2093 5.84211H19.6744V6.31579H19.2093V5.84211Z" fill="white" />
                    <path d="M19.6744 5.84211H20.1395V6.31579H19.6744V5.84211Z" fill="white" />
                    <path d="M20.1395 5.84211H20.6047V6.31579H20.1395V5.84211Z" fill="white" />
                    <path d="M20.6047 5.84211H21.0698V6.31579H20.6047V5.84211Z" fill="white" />
                    <path d="M21.0698 5.84211H21.5349V6.31579H21.0698V5.84211Z" fill="white" />
                    <path d="M21.5349 5.84211H22V6.31579H21.5349V5.84211Z" fill="white" />
                    <path d="M10.3721 6.31579H10.8372V6.78947H10.3721V6.31579Z" fill="white" />
                    <path d="M10.8372 6.31579H11.3023V6.78947H10.8372V6.31579Z" fill="white" />
                    <path d="M11.3023 6.31579H11.7674V6.78947H11.3023V6.31579Z" fill="white" />
                    <path d="M11.7674 6.31579H12.2326V6.78947H11.7674V6.31579Z" fill="white" />
                    <path d="M12.2326 6.31579H12.6977V6.78947H12.2326V6.31579Z" fill="white" />
                    <path d="M12.6977 6.31579H13.1628V6.78947H12.6977V6.31579Z" fill="white" />
                    <path d="M13.1628 6.31579H13.6279V6.78947H13.1628V6.31579Z" fill="white" />
                    <path d="M13.6279 6.31579H14.093V6.78947H13.6279V6.31579Z" fill="white" />
                    <path d="M14.093 6.31579H14.5581V6.78947H14.093V6.31579Z" fill="white" />
                    <path d="M14.5581 6.31579H15.0233V6.78947H14.5581V6.31579Z" fill="white" />
                    <path d="M15.0233 6.31579H15.4884V6.78947H15.0233V6.31579Z" fill="white" />
                    <path d="M15.4884 6.31579H15.9535V6.78947H15.4884V6.31579Z" fill="white" />
                    <path d="M15.9535 6.31579H16.4186V6.78947H15.9535V6.31579Z" fill="white" />
                    <path d="M16.4186 6.31579H16.8837V6.78947H16.4186V6.31579Z" fill="white" />
                    <path d="M16.8837 6.31579H17.3488V6.78947H16.8837V6.31579Z" fill="white" />
                    <path d="M17.3488 6.31579H17.814V6.78947H17.3488V6.31579Z" fill="white" />
                    <path d="M17.814 6.31579H18.2791V6.78947H17.814V6.31579Z" fill="white" />
                    <path d="M18.2791 6.31579H18.7442V6.78947H18.2791V6.31579Z" fill="white" />
                    <path d="M18.7442 6.31579H19.2093V6.78947H18.7442V6.31579Z" fill="white" />
                    <path d="M19.2093 6.31579H19.6744V6.78947H19.2093V6.31579Z" fill="white" />
                    <path d="M19.6744 6.31579H20.1395V6.78947H19.6744V6.31579Z" fill="white" />
                    <path d="M20.1395 6.31579H20.6047V6.78947H20.1395V6.31579Z" fill="white" />
                    <path d="M20.6047 6.31579H21.0698V6.78947H20.6047V6.31579Z" fill="white" />
                    <path d="M21.0698 6.31579H21.5349V6.78947H21.0698V6.31579Z" fill="white" />
                    <path d="M21.5349 6.31579H22V6.78947H21.5349V6.31579Z" fill="white" />
                    <path d="M10.3721 6.78947H10.8372V7.26316H10.3721V6.78947Z" fill="white" />
                    <path d="M10.8372 6.78947H11.3023V7.26316H10.8372V6.78947Z" fill="white" />
                    <path d="M11.3023 6.78947H11.7674V7.26316H11.3023V6.78947Z" fill="white" />
                    <path d="M11.7674 6.78947H12.2326V7.26316H11.7674V6.78947Z" fill="white" />
                    <path d="M12.2326 6.78947H12.6977V7.26316H12.2326V6.78947Z" fill="white" />
                    <path d="M12.6977 6.78947H13.1628V7.26316H12.6977V6.78947Z" fill="white" />
                    <path d="M13.1628 6.78947H13.6279V7.26316H13.1628V6.78947Z" fill="white" />
                    <path d="M13.6279 6.78947H14.093V7.26316H13.6279V6.78947Z" fill="white" />
                    <path d="M14.093 6.78947H14.5581V7.26316H14.093V6.78947Z" fill="white" />
                    <path d="M14.5581 6.78947H15.0233V7.26316H14.5581V6.78947Z" fill="white" />
                    <path d="M15.0233 6.78947H15.4884V7.26316H15.0233V6.78947Z" fill="white" />
                    <path d="M15.4884 6.78947H15.9535V7.26316H15.4884V6.78947Z" fill="white" />
                    <path d="M15.9535 6.78947H16.4186V7.26316H15.9535V6.78947Z" fill="white" />
                    <path d="M16.4186 6.78947H16.8837V7.26316H16.4186V6.78947Z" fill="white" />
                    <path d="M16.8837 6.78947H17.3488V7.26316H16.8837V6.78947Z" fill="white" />
                    <path d="M17.3488 6.78947H17.814V7.26316H17.3488V6.78947Z" fill="white" />
                    <path d="M17.814 6.78947H18.2791V7.26316H17.814V6.78947Z" fill="white" />
                    <path d="M18.2791 6.78947H18.7442V7.26316H18.2791V6.78947Z" fill="white" />
                    <path d="M18.7442 6.78947H19.2093V7.26316H18.7442V6.78947Z" fill="white" />
                    <path d="M19.2093 6.78947H19.6744V7.26316H19.2093V6.78947Z" fill="white" />
                    <path d="M19.6744 6.78947H20.1395V7.26316H19.6744V6.78947Z" fill="white" />
                    <path d="M20.1395 6.78947H20.6047V7.26316H20.1395V6.78947Z" fill="white" />
                    <path d="M20.6047 6.78947H21.0698V7.26316H20.6047V6.78947Z" fill="white" />
                    <path d="M21.0698 6.78947H21.5349V7.26316H21.0698V6.78947Z" fill="white" />
                    <path d="M10.8372 7.26316H11.3023V7.73684H10.8372V7.26316Z" fill="white" />
                    <path d="M11.3023 7.26316H11.7674V7.73684H11.3023V7.26316Z" fill="white" />
                    <path d="M11.7674 7.26316H12.2326V7.73684H11.7674V7.26316Z" fill="white" />
                    <path d="M12.2326 7.26316H12.6977V7.73684H12.2326V7.26316Z" fill="white" />
                    <path d="M12.6977 7.26316H13.1628V7.73684H12.6977V7.26316Z" fill="white" />
                    <path d="M13.1628 7.26316H13.6279V7.73684H13.1628V7.26316Z" fill="white" />
                    <path d="M13.6279 7.26316H14.093V7.73684H13.6279V7.26316Z" fill="white" />
                    <path d="M14.093 7.26316H14.5581V7.73684H14.093V7.26316Z" fill="white" />
                    <path d="M14.5581 7.26316H15.0233V7.73684H14.5581V7.26316Z" fill="white" />
                    <path d="M15.0233 7.26316H15.4884V7.73684H15.0233V7.26316Z" fill="white" />
                    <path d="M15.4884 7.26316H15.9535V7.73684H15.4884V7.26316Z" fill="white" />
                    <path d="M15.9535 7.26316H16.4186V7.73684H15.9535V7.26316Z" fill="white" />
                    <path d="M16.4186 7.26316H16.8837V7.73684H16.4186V7.26316Z" fill="white" />
                    <path d="M16.8837 7.26316H17.3488V7.73684H16.8837V7.26316Z" fill="white" />
                    <path d="M17.3488 7.26316H17.814V7.73684H17.3488V7.26316Z" fill="white" />
                    <path d="M17.814 7.26316H18.2791V7.73684H17.814V7.26316Z" fill="white" />
                    <path d="M18.2791 7.26316H18.7442V7.73684H18.2791V7.26316Z" fill="white" />
                    <path d="M18.7442 7.26316H19.2093V7.73684H18.7442V7.26316Z" fill="white" />
                    <path d="M19.2093 7.26316H19.6744V7.73684H19.2093V7.26316Z" fill="white" />
                    <path d="M19.6744 7.26316H20.1395V7.73684H19.6744V7.26316Z" fill="white" />
                    <path d="M20.1395 7.26316H20.6047V7.73684H20.1395V7.26316Z" fill="white" />
                    <path d="M20.6047 7.26316H21.0698V7.73684H20.6047V7.26316Z" fill="white" />
                    <path d="M21.0698 7.26316H21.5349V7.73684H21.0698V7.26316Z" fill="white" />
                    <path d="M5.25581 7.73684H5.72093V8.21053H5.25581V7.73684Z" fill="white" />
                    <path d="M5.72093 7.73684H6.18605V8.21053H5.72093V7.73684Z" fill="white" />
                    <path d="M6.18605 7.73684H6.65116V8.21053H6.18605V7.73684Z" fill="white" />
                    <path d="M6.65116 7.73684H7.11628V8.21053H6.65116V7.73684Z" fill="white" />
                    <path d="M7.11628 7.73684H7.5814V8.21053H7.11628V7.73684Z" fill="white" />
                    <path d="M7.5814 7.73684H8.04651V8.21053H7.5814V7.73684Z" fill="white" />
                    <path d="M8.04651 7.73684H8.51163V8.21053H8.04651V7.73684Z" fill="white" />
                    <path d="M8.51163 7.73684H8.97674V8.21053H8.51163V7.73684Z" fill="white" />
                    <path d="M8.97674 7.73684H9.44186V8.21053H8.97674V7.73684Z" fill="white" />
                    <path d="M11.3023 7.73684H11.7674V8.21053H11.3023V7.73684Z" fill="white" />
                    <path d="M11.7674 7.73684H12.2326V8.21053H11.7674V7.73684Z" fill="white" />
                    <path d="M12.2326 7.73684H12.6977V8.21053H12.2326V7.73684Z" fill="white" />
                    <path d="M12.6977 7.73684H13.1628V8.21053H12.6977V7.73684Z" fill="white" />
                    <path d="M13.1628 7.73684H13.6279V8.21053H13.1628V7.73684Z" fill="white" />
                    <path d="M13.6279 7.73684H14.093V8.21053H13.6279V7.73684Z" fill="white" />
                    <path d="M14.093 7.73684H14.5581V8.21053H14.093V7.73684Z" fill="white" />
                    <path d="M14.5581 7.73684H15.0233V8.21053H14.5581V7.73684Z" fill="white" />
                    <path d="M15.0233 7.73684H15.4884V8.21053H15.0233V7.73684Z" fill="white" />
                    <path d="M15.4884 7.73684H15.9535V8.21053H15.4884V7.73684Z" fill="white" />
                    <path d="M15.9535 7.73684H16.4186V8.21053H15.9535V7.73684Z" fill="white" />
                    <path d="M16.4186 7.73684H16.8837V8.21053H16.4186V7.73684Z" fill="white" />
                    <path d="M16.8837 7.73684H17.3488V8.21053H16.8837V7.73684Z" fill="white" />
                    <path d="M17.3488 7.73684H17.814V8.21053H17.3488V7.73684Z" fill="white" />
                    <path d="M17.814 7.73684H18.2791V8.21053H17.814V7.73684Z" fill="white" />
                    <path d="M18.2791 7.73684H18.7442V8.21053H18.2791V7.73684Z" fill="white" />
                    <path d="M18.7442 7.73684H19.2093V8.21053H18.7442V7.73684Z" fill="white" />
                    <path d="M19.2093 7.73684H19.6744V8.21053H19.2093V7.73684Z" fill="white" />
                    <path d="M19.6744 7.73684H20.1395V8.21053H19.6744V7.73684Z" fill="white" />
                    <path d="M20.1395 7.73684H20.6047V8.21053H20.1395V7.73684Z" fill="white" />
                    <path d="M3.86047 8.21053H4.32558V8.68421H3.86047V8.21053Z" fill="white" />
                    <path d="M4.32558 8.21053H4.7907V8.68421H4.32558V8.21053Z" fill="white" />
                    <path d="M4.7907 8.21053H5.25581V8.68421H4.7907V8.21053Z" fill="white" />
                    <path d="M5.25581 8.21053H5.72093V8.68421H5.25581V8.21053Z" fill="white" />
                    <path d="M5.72093 8.21053H6.18605V8.68421H5.72093V8.21053Z" fill="white" />
                    <path d="M6.18605 8.21053H6.65116V8.68421H6.18605V8.21053Z" fill="white" />
                    <path d="M6.65116 8.21053H7.11628V8.68421H6.65116V8.21053Z" fill="white" />
                    <path d="M7.11628 8.21053H7.5814V8.68421H7.11628V8.21053Z" fill="white" />
                    <path d="M7.5814 8.21053H8.04651V8.68421H7.5814V8.21053Z" fill="white" />
                    <path d="M8.04651 8.21053H8.51163V8.68421H8.04651V8.21053Z" fill="white" />
                    <path d="M8.51163 8.21053H8.97674V8.68421H8.51163V8.21053Z" fill="white" />
                    <path d="M8.97674 8.21053H9.44186V8.68421H8.97674V8.21053Z" fill="white" />
                    <path d="M10.3721 8.21053H10.8372V8.68421H10.3721V8.21053Z" fill="white" />
                    <path d="M12.2326 8.21053H12.6977V8.68421H12.2326V8.21053Z" fill="white" />
                    <path d="M12.6977 8.21053H13.1628V8.68421H12.6977V8.21053Z" fill="white" />
                    <path d="M13.1628 8.21053H13.6279V8.68421H13.1628V8.21053Z" fill="white" />
                    <path d="M13.6279 8.21053H14.093V8.68421H13.6279V8.21053Z" fill="white" />
                    <path d="M14.093 8.21053H14.5581V8.68421H14.093V8.21053Z" fill="white" />
                    <path d="M14.5581 8.21053H15.0233V8.68421H14.5581V8.21053Z" fill="white" />
                    <path d="M15.0233 8.21053H15.4884V8.68421H15.0233V8.21053Z" fill="white" />
                    <path d="M15.4884 8.21053H15.9535V8.68421H15.4884V8.21053Z" fill="white" />
                    <path d="M15.9535 8.21053H16.4186V8.68421H15.9535V8.21053Z" fill="white" />
                    <path d="M16.4186 8.21053H16.8837V8.68421H16.4186V8.21053Z" fill="white" />
                    <path d="M16.8837 8.21053H17.3488V8.68421H16.8837V8.21053Z" fill="white" />
                    <path d="M17.3488 8.21053H17.814V8.68421H17.3488V8.21053Z" fill="white" />
                    <path d="M17.814 8.21053H18.2791V8.68421H17.814V8.21053Z" fill="white" />
                    <path d="M18.2791 8.21053H18.7442V8.68421H18.2791V8.21053Z" fill="white" />
                    <path d="M18.7442 8.21053H19.2093V8.68421H18.7442V8.21053Z" fill="white" />
                    <path d="M19.2093 8.21053H19.6744V8.68421H19.2093V8.21053Z" fill="white" />
                    <path d="M21.5349 8.21053H22V8.68421H21.5349V8.21053Z" fill="white" />
                    <path d="M2.93023 8.68421H3.39535V9.15789H2.93023V8.68421Z" fill="white" />
                    <path d="M3.39535 8.68421H3.86047V9.15789H3.39535V8.68421Z" fill="white" />
                    <path d="M3.86047 8.68421H4.32558V9.15789H3.86047V8.68421Z" fill="white" />
                    <path d="M4.32558 8.68421H4.7907V9.15789H4.32558V8.68421Z" fill="white" />
                    <path d="M4.7907 8.68421H5.25581V9.15789H4.7907V8.68421Z" fill="white" />
                    <path d="M5.25581 8.68421H5.72093V9.15789H5.25581V8.68421Z" fill="white" />
                    <path d="M5.72093 8.68421H6.18605V9.15789H5.72093V8.68421Z" fill="white" />
                    <path d="M6.18605 8.68421H6.65116V9.15789H6.18605V8.68421Z" fill="white" />
                    <path d="M6.65116 8.68421H7.11628V9.15789H6.65116V8.68421Z" fill="white" />
                    <path d="M7.11628 8.68421H7.5814V9.15789H7.11628V8.68421Z" fill="white" />
                    <path d="M7.5814 8.68421H8.04651V9.15789H7.5814V8.68421Z" fill="white" />
                    <path d="M8.04651 8.68421H8.51163V9.15789H8.04651V8.68421Z" fill="white" />
                    <path d="M8.51163 8.68421H8.97674V9.15789H8.51163V8.68421Z" fill="white" />
                    <path d="M8.97674 8.68421H9.44186V9.15789H8.97674V8.68421Z" fill="white" />
                    <path d="M10.3721 8.68421H10.8372V9.15789H10.3721V8.68421Z" fill="white" />
                    <path d="M10.8372 8.68421H11.3023V9.15789H10.8372V8.68421Z" fill="white" />
                    <path d="M14.093 8.68421H14.5581V9.15789H14.093V8.68421Z" fill="white" />
                    <path d="M14.5581 8.68421H15.0233V9.15789H14.5581V8.68421Z" fill="white" />
                    <path d="M15.0233 8.68421H15.4884V9.15789H15.0233V8.68421Z" fill="white" />
                    <path d="M15.4884 8.68421H15.9535V9.15789H15.4884V8.68421Z" fill="white" />
                    <path d="M15.9535 8.68421H16.4186V9.15789H15.9535V8.68421Z" fill="white" />
                    <path d="M16.4186 8.68421H16.8837V9.15789H16.4186V8.68421Z" fill="white" />
                    <path d="M16.8837 8.68421H17.3488V9.15789H16.8837V8.68421Z" fill="white" />
                    <path d="M17.3488 8.68421H17.814V9.15789H17.3488V8.68421Z" fill="white" />
                    <path d="M17.814 8.68421H18.2791V9.15789H17.814V8.68421Z" fill="white" />
                    <path d="M21.0698 8.68421H21.5349V9.15789H21.0698V8.68421Z" fill="white" />
                    <path d="M21.5349 8.68421H22V9.15789H21.5349V8.68421Z" fill="white" />
                    <path d="M2.46512 9.15789H2.93023V9.63158H2.46512V9.15789Z" fill="white" />
                    <path d="M2.93023 9.15789H3.39535V9.63158H2.93023V9.15789Z" fill="white" />
                    <path d="M3.39535 9.15789H3.86047V9.63158H3.39535V9.15789Z" fill="white" />
                    <path d="M3.86047 9.15789H4.32558V9.63158H3.86047V9.15789Z" fill="white" />
                    <path d="M4.32558 9.15789H4.7907V9.63158H4.32558V9.15789Z" fill="white" />
                    <path d="M4.7907 9.15789H5.25581V9.63158H4.7907V9.15789Z" fill="white" />
                    <path d="M5.25581 9.15789H5.72093V9.63158H5.25581V9.15789Z" fill="white" />
                    <path d="M5.72093 9.15789H6.18605V9.63158H5.72093V9.15789Z" fill="white" />
                    <path d="M6.18605 9.15789H6.65116V9.63158H6.18605V9.15789Z" fill="white" />
                    <path d="M6.65116 9.15789H7.11628V9.63158H6.65116V9.15789Z" fill="white" />
                    <path d="M7.11628 9.15789H7.5814V9.63158H7.11628V9.15789Z" fill="white" />
                    <path d="M7.5814 9.15789H8.04651V9.63158H7.5814V9.15789Z" fill="white" />
                    <path d="M8.04651 9.15789H8.51163V9.63158H8.04651V9.15789Z" fill="white" />
                    <path d="M8.51163 9.15789H8.97674V9.63158H8.51163V9.15789Z" fill="white" />
                    <path d="M8.97674 9.15789H9.44186V9.63158H8.97674V9.15789Z" fill="white" />
                    <path d="M10.3721 9.15789H10.8372V9.63158H10.3721V9.15789Z" fill="white" />
                    <path d="M10.8372 9.15789H11.3023V9.63158H10.8372V9.15789Z" fill="white" />
                    <path d="M11.3023 9.15789H11.7674V9.63158H11.3023V9.15789Z" fill="white" />
                    <path d="M20.6047 9.15789H21.0698V9.63158H20.6047V9.15789Z" fill="white" />
                    <path d="M21.0698 9.15789H21.5349V9.63158H21.0698V9.15789Z" fill="white" />
                    <path d="M21.5349 9.15789H22V9.63158H21.5349V9.15789Z" fill="white" />
                    <path d="M2.46512 9.63158H2.93023V10.1053H2.46512V9.63158Z" fill="white" />
                    <path d="M2.93023 9.63158H3.39535V10.1053H2.93023V9.63158Z" fill="white" />
                    <path d="M3.39535 9.63158H3.86047V10.1053H3.39535V9.63158Z" fill="white" />
                    <path d="M3.86047 9.63158H4.32558V10.1053H3.86047V9.63158Z" fill="white" />
                    <path d="M4.32558 9.63158H4.7907V10.1053H4.32558V9.63158Z" fill="white" />
                    <path d="M4.7907 9.63158H5.25581V10.1053H4.7907V9.63158Z" fill="white" />
                    <path d="M5.25581 9.63158H5.72093V10.1053H5.25581V9.63158Z" fill="white" />
                    <path d="M5.72093 9.63158H6.18605V10.1053H5.72093V9.63158Z" fill="white" />
                    <path d="M6.18605 9.63158H6.65116V10.1053H6.18605V9.63158Z" fill="white" />
                    <path d="M6.65116 9.63158H7.11628V10.1053H6.65116V9.63158Z" fill="white" />
                    <path d="M7.11628 9.63158H7.5814V10.1053H7.11628V9.63158Z" fill="white" />
                    <path d="M7.5814 9.63158H8.04651V10.1053H7.5814V9.63158Z" fill="white" />
                    <path d="M8.04651 9.63158H8.51163V10.1053H8.04651V9.63158Z" fill="white" />
                    <path d="M8.51163 9.63158H8.97674V10.1053H8.51163V9.63158Z" fill="white" />
                    <path d="M8.97674 9.63158H9.44186V10.1053H8.97674V9.63158Z" fill="white" />
                    <path d="M10.3721 9.63158H10.8372V10.1053H10.3721V9.63158Z" fill="white" />
                    <path d="M10.8372 9.63158H11.3023V10.1053H10.8372V9.63158Z" fill="white" />
                    <path d="M11.3023 9.63158H11.7674V10.1053H11.3023V9.63158Z" fill="white" />
                    <path d="M11.7674 9.63158H12.2326V10.1053H11.7674V9.63158Z" fill="white" />
                    <path d="M12.2326 9.63158H12.6977V10.1053H12.2326V9.63158Z" fill="white" />
                    <path d="M19.6744 9.63158H20.1395V10.1053H19.6744V9.63158Z" fill="white" />
                    <path d="M20.1395 9.63158H20.6047V10.1053H20.1395V9.63158Z" fill="white" />
                    <path d="M20.6047 9.63158H21.0698V10.1053H20.6047V9.63158Z" fill="white" />
                    <path d="M21.0698 9.63158H21.5349V10.1053H21.0698V9.63158Z" fill="white" />
                    <path d="M21.5349 9.63158H22V10.1053H21.5349V9.63158Z" fill="white" />
                    <path d="M2 10.1053H2.46512V10.5789H2V10.1053Z" fill="white" />
                    <path d="M2.46512 10.1053H2.93023V10.5789H2.46512V10.1053Z" fill="white" />
                    <path d="M2.93023 10.1053H3.39535V10.5789H2.93023V10.1053Z" fill="white" />
                    <path d="M3.39535 10.1053H3.86047V10.5789H3.39535V10.1053Z" fill="white" />
                    <path d="M3.86047 10.1053H4.32558V10.5789H3.86047V10.1053Z" fill="white" />
                    <path d="M4.32558 10.1053H4.7907V10.5789H4.32558V10.1053Z" fill="white" />
                    <path d="M4.7907 10.1053H5.25581V10.5789H4.7907V10.1053Z" fill="white" />
                    <path d="M5.25581 10.1053H5.72093V10.5789H5.25581V10.1053Z" fill="white" />
                    <path d="M5.72093 10.1053H6.18605V10.5789H5.72093V10.1053Z" fill="white" />
                    <path d="M6.18605 10.1053H6.65116V10.5789H6.18605V10.1053Z" fill="white" />
                    <path d="M6.65116 10.1053H7.11628V10.5789H6.65116V10.1053Z" fill="white" />
                    <path d="M7.11628 10.1053H7.5814V10.5789H7.11628V10.1053Z" fill="white" />
                    <path d="M7.5814 10.1053H8.04651V10.5789H7.5814V10.1053Z" fill="white" />
                    <path d="M8.04651 10.1053H8.51163V10.5789H8.04651V10.1053Z" fill="white" />
                    <path d="M8.51163 10.1053H8.97674V10.5789H8.51163V10.1053Z" fill="white" />
                    <path d="M8.97674 10.1053H9.44186V10.5789H8.97674V10.1053Z" fill="white" />
                    <path d="M10.8372 10.1053H11.3023V10.5789H10.8372V10.1053Z" fill="white" />
                    <path d="M11.3023 10.1053H11.7674V10.5789H11.3023V10.1053Z" fill="white" />
                    <path d="M11.7674 10.1053H12.2326V10.5789H11.7674V10.1053Z" fill="white" />
                    <path d="M12.2326 10.1053H12.6977V10.5789H12.2326V10.1053Z" fill="white" />
                    <path d="M12.6977 10.1053H13.1628V10.5789H12.6977V10.1053Z" fill="white" />
                    <path d="M13.1628 10.1053H13.6279V10.5789H13.1628V10.1053Z" fill="white" />
                    <path d="M13.6279 10.1053H14.093V10.5789H13.6279V10.1053Z" fill="white" />
                    <path d="M14.093 10.1053H14.5581V10.5789H14.093V10.1053Z" fill="white" />
                    <path d="M14.5581 10.1053H15.0233V10.5789H14.5581V10.1053Z" fill="white" />
                    <path d="M15.0233 10.1053H15.4884V10.5789H15.0233V10.1053Z" fill="white" />
                    <path d="M16.8837 10.1053H17.3488V10.5789H16.8837V10.1053Z" fill="white" />
                    <path d="M17.3488 10.1053H17.814V10.5789H17.3488V10.1053Z" fill="white" />
                    <path d="M17.814 10.1053H18.2791V10.5789H17.814V10.1053Z" fill="white" />
                    <path d="M18.2791 10.1053H18.7442V10.5789H18.2791V10.1053Z" fill="white" />
                    <path d="M18.7442 10.1053H19.2093V10.5789H18.7442V10.1053Z" fill="white" />
                    <path d="M19.2093 10.1053H19.6744V10.5789H19.2093V10.1053Z" fill="white" />
                    <path d="M19.6744 10.1053H20.1395V10.5789H19.6744V10.1053Z" fill="white" />
                    <path d="M20.1395 10.1053H20.6047V10.5789H20.1395V10.1053Z" fill="white" />
                    <path d="M20.6047 10.1053H21.0698V10.5789H20.6047V10.1053Z" fill="white" />
                    <path d="M21.0698 10.1053H21.5349V10.5789H21.0698V10.1053Z" fill="white" />
                    <path d="M2 10.5789H2.46512V11.0526H2V10.5789Z" fill="white" />
                    <path d="M2.46512 10.5789H2.93023V11.0526H2.46512V10.5789Z" fill="white" />
                    <path d="M2.93023 10.5789H3.39535V11.0526H2.93023V10.5789Z" fill="white" />
                    <path d="M3.39535 10.5789H3.86047V11.0526H3.39535V10.5789Z" fill="white" />
                    <path d="M3.86047 10.5789H4.32558V11.0526H3.86047V10.5789Z" fill="white" />
                    <path d="M4.32558 10.5789H4.7907V11.0526H4.32558V10.5789Z" fill="white" />
                    <path d="M4.7907 10.5789H5.25581V11.0526H4.7907V10.5789Z" fill="white" />
                    <path d="M5.25581 10.5789H5.72093V11.0526H5.25581V10.5789Z" fill="white" />
                    <path d="M5.72093 10.5789H6.18605V11.0526H5.72093V10.5789Z" fill="white" />
                    <path d="M6.18605 10.5789H6.65116V11.0526H6.18605V10.5789Z" fill="white" />
                    <path d="M6.65116 10.5789H7.11628V11.0526H6.65116V10.5789Z" fill="white" />
                    <path d="M7.11628 10.5789H7.5814V11.0526H7.11628V10.5789Z" fill="white" />
                    <path d="M7.5814 10.5789H8.04651V11.0526H7.5814V10.5789Z" fill="white" />
                    <path d="M8.04651 10.5789H8.51163V11.0526H8.04651V10.5789Z" fill="white" />
                    <path d="M8.51163 10.5789H8.97674V11.0526H8.51163V10.5789Z" fill="white" />
                    <path d="M8.97674 10.5789H9.44186V11.0526H8.97674V10.5789Z" fill="white" />
                    <path d="M11.3023 10.5789H11.7674V11.0526H11.3023V10.5789Z" fill="white" />
                    <path d="M11.7674 10.5789H12.2326V11.0526H11.7674V10.5789Z" fill="white" />
                    <path d="M12.2326 10.5789H12.6977V11.0526H12.2326V10.5789Z" fill="white" />
                    <path d="M12.6977 10.5789H13.1628V11.0526H12.6977V10.5789Z" fill="white" />
                    <path d="M13.1628 10.5789H13.6279V11.0526H13.1628V10.5789Z" fill="white" />
                    <path d="M13.6279 10.5789H14.093V11.0526H13.6279V10.5789Z" fill="white" />
                    <path d="M14.093 10.5789H14.5581V11.0526H14.093V10.5789Z" fill="white" />
                    <path d="M14.5581 10.5789H15.0233V11.0526H14.5581V10.5789Z" fill="white" />
                    <path d="M15.0233 10.5789H15.4884V11.0526H15.0233V10.5789Z" fill="white" />
                    <path d="M15.4884 10.5789H15.9535V11.0526H15.4884V10.5789Z" fill="white" />
                    <path d="M15.9535 10.5789H16.4186V11.0526H15.9535V10.5789Z" fill="white" />
                    <path d="M16.4186 10.5789H16.8837V11.0526H16.4186V10.5789Z" fill="white" />
                    <path d="M16.8837 10.5789H17.3488V11.0526H16.8837V10.5789Z" fill="white" />
                    <path d="M17.3488 10.5789H17.814V11.0526H17.3488V10.5789Z" fill="white" />
                    <path d="M17.814 10.5789H18.2791V11.0526H17.814V10.5789Z" fill="white" />
                    <path d="M18.2791 10.5789H18.7442V11.0526H18.2791V10.5789Z" fill="white" />
                    <path d="M18.7442 10.5789H19.2093V11.0526H18.7442V10.5789Z" fill="white" />
                    <path d="M19.2093 10.5789H19.6744V11.0526H19.2093V10.5789Z" fill="white" />
                    <path d="M19.6744 10.5789H20.1395V11.0526H19.6744V10.5789Z" fill="white" />
                    <path d="M20.1395 10.5789H20.6047V11.0526H20.1395V10.5789Z" fill="white" />
                    <path d="M20.6047 10.5789H21.0698V11.0526H20.6047V10.5789Z" fill="white" />
                    <path d="M2.46512 11.0526H2.93023V11.5263H2.46512V11.0526Z" fill="white" />
                    <path d="M2.93023 11.0526H3.39535V11.5263H2.93023V11.0526Z" fill="white" />
                    <path d="M3.39535 11.0526H3.86047V11.5263H3.39535V11.0526Z" fill="white" />
                    <path d="M3.86047 11.0526H4.32558V11.5263H3.86047V11.0526Z" fill="white" />
                    <path d="M4.32558 11.0526H4.7907V11.5263H4.32558V11.0526Z" fill="white" />
                    <path d="M4.7907 11.0526H5.25581V11.5263H4.7907V11.0526Z" fill="white" />
                    <path d="M5.25581 11.0526H5.72093V11.5263H5.25581V11.0526Z" fill="white" />
                    <path d="M5.72093 11.0526H6.18605V11.5263H5.72093V11.0526Z" fill="white" />
                    <path d="M6.18605 11.0526H6.65116V11.5263H6.18605V11.0526Z" fill="white" />
                    <path d="M6.65116 11.0526H7.11628V11.5263H6.65116V11.0526Z" fill="white" />
                    <path d="M7.11628 11.0526H7.5814V11.5263H7.11628V11.0526Z" fill="white" />
                    <path d="M7.5814 11.0526H8.04651V11.5263H7.5814V11.0526Z" fill="white" />
                    <path d="M8.04651 11.0526H8.51163V11.5263H8.04651V11.0526Z" fill="white" />
                    <path d="M8.51163 11.0526H8.97674V11.5263H8.51163V11.0526Z" fill="white" />
                    <path d="M8.97674 11.0526H9.44186V11.5263H8.97674V11.0526Z" fill="white" />
                    <path d="M10.3721 11.0526H10.8372V11.5263H10.3721V11.0526Z" fill="white" />
                    <path d="M12.2326 11.0526H12.6977V11.5263H12.2326V11.0526Z" fill="white" />
                    <path d="M12.6977 11.0526H13.1628V11.5263H12.6977V11.0526Z" fill="white" />
                    <path d="M13.1628 11.0526H13.6279V11.5263H13.1628V11.0526Z" fill="white" />
                    <path d="M13.6279 11.0526H14.093V11.5263H13.6279V11.0526Z" fill="white" />
                    <path d="M14.093 11.0526H14.5581V11.5263H14.093V11.0526Z" fill="white" />
                    <path d="M14.5581 11.0526H15.0233V11.5263H14.5581V11.0526Z" fill="white" />
                    <path d="M15.0233 11.0526H15.4884V11.5263H15.0233V11.0526Z" fill="white" />
                    <path d="M15.4884 11.0526H15.9535V11.5263H15.4884V11.0526Z" fill="white" />
                    <path d="M15.9535 11.0526H16.4186V11.5263H15.9535V11.0526Z" fill="white" />
                    <path d="M16.4186 11.0526H16.8837V11.5263H16.4186V11.0526Z" fill="white" />
                    <path d="M16.8837 11.0526H17.3488V11.5263H16.8837V11.0526Z" fill="white" />
                    <path d="M17.3488 11.0526H17.814V11.5263H17.3488V11.0526Z" fill="white" />
                    <path d="M17.814 11.0526H18.2791V11.5263H17.814V11.0526Z" fill="white" />
                    <path d="M18.2791 11.0526H18.7442V11.5263H18.2791V11.0526Z" fill="white" />
                    <path d="M18.7442 11.0526H19.2093V11.5263H18.7442V11.0526Z" fill="white" />
                    <path d="M19.2093 11.0526H19.6744V11.5263H19.2093V11.0526Z" fill="white" />
                    <path d="M19.6744 11.0526H20.1395V11.5263H19.6744V11.0526Z" fill="white" />
                    <path d="M21.5349 11.0526H22V11.5263H21.5349V11.0526Z" fill="white" />
                    <path d="M2.46512 11.5263H2.93023V12H2.46512V11.5263Z" fill="white" />
                    <path d="M2.93023 11.5263H3.39535V12H2.93023V11.5263Z" fill="white" />
                    <path d="M3.39535 11.5263H3.86047V12H3.39535V11.5263Z" fill="white" />
                    <path d="M3.86047 11.5263H4.32558V12H3.86047V11.5263Z" fill="white" />
                    <path d="M4.32558 11.5263H4.7907V12H4.32558V11.5263Z" fill="white" />
                    <path d="M4.7907 11.5263H5.25581V12H4.7907V11.5263Z" fill="white" />
                    <path d="M5.25581 11.5263H5.72093V12H5.25581V11.5263Z" fill="white" />
                    <path d="M5.72093 11.5263H6.18605V12H5.72093V11.5263Z" fill="white" />
                    <path d="M6.18605 11.5263H6.65116V12H6.18605V11.5263Z" fill="white" />
                    <path d="M6.65116 11.5263H7.11628V12H6.65116V11.5263Z" fill="white" />
                    <path d="M7.11628 11.5263H7.5814V12H7.11628V11.5263Z" fill="white" />
                    <path d="M7.5814 11.5263H8.04651V12H7.5814V11.5263Z" fill="white" />
                    <path d="M8.04651 11.5263H8.51163V12H8.04651V11.5263Z" fill="white" />
                    <path d="M8.51163 11.5263H8.97674V12H8.51163V11.5263Z" fill="white" />
                    <path d="M8.97674 11.5263H9.44186V12H8.97674V11.5263Z" fill="white" />
                    <path d="M10.3721 11.5263H10.8372V12H10.3721V11.5263Z" fill="white" />
                    <path d="M13.6279 11.5263H14.093V12H13.6279V11.5263Z" fill="white" />
                    <path d="M14.093 11.5263H14.5581V12H14.093V11.5263Z" fill="white" />
                    <path d="M14.5581 11.5263H15.0233V12H14.5581V11.5263Z" fill="white" />
                    <path d="M15.0233 11.5263H15.4884V12H15.0233V11.5263Z" fill="white" />
                    <path d="M15.4884 11.5263H15.9535V12H15.4884V11.5263Z" fill="white" />
                    <path d="M15.9535 11.5263H16.4186V12H15.9535V11.5263Z" fill="white" />
                    <path d="M16.4186 11.5263H16.8837V12H16.4186V11.5263Z" fill="white" />
                    <path d="M16.8837 11.5263H17.3488V12H16.8837V11.5263Z" fill="white" />
                    <path d="M17.3488 11.5263H17.814V12H17.3488V11.5263Z" fill="white" />
                    <path d="M17.814 11.5263H18.2791V12H17.814V11.5263Z" fill="white" />
                    <path d="M18.2791 11.5263H18.7442V12H18.2791V11.5263Z" fill="white" />
                    <path d="M21.5349 11.5263H22V12H21.5349V11.5263Z" fill="white" />
                    <path d="M2.93023 12H3.39535V12.4737H2.93023V12Z" fill="white" />
                    <path d="M3.39535 12H3.86047V12.4737H3.39535V12Z" fill="white" />
                    <path d="M3.86047 12H4.32558V12.4737H3.86047V12Z" fill="white" />
                    <path d="M4.32558 12H4.7907V12.4737H4.32558V12Z" fill="white" />
                    <path d="M4.7907 12H5.25581V12.4737H4.7907V12Z" fill="white" />
                    <path d="M5.25581 12H5.72093V12.4737H5.25581V12Z" fill="white" />
                    <path d="M5.72093 12H6.18605V12.4737H5.72093V12Z" fill="white" />
                    <path d="M6.18605 12H6.65116V12.4737H6.18605V12Z" fill="white" />
                    <path d="M6.65116 12H7.11628V12.4737H6.65116V12Z" fill="white" />
                    <path d="M7.11628 12H7.5814V12.4737H7.11628V12Z" fill="white" />
                    <path d="M7.5814 12H8.04651V12.4737H7.5814V12Z" fill="white" />
                    <path d="M8.04651 12H8.51163V12.4737H8.04651V12Z" fill="white" />
                    <path d="M8.51163 12H8.97674V12.4737H8.51163V12Z" fill="white" />
                    <path d="M8.97674 12H9.44186V12.4737H8.97674V12Z" fill="white" />
                    <path d="M10.3721 12H10.8372V12.4737H10.3721V12Z" fill="white" />
                    <path d="M10.8372 12H11.3023V12.4737H10.8372V12Z" fill="white" />
                    <path d="M11.3023 12H11.7674V12.4737H11.3023V12Z" fill="white" />
                    <path d="M20.6047 12H21.0698V12.4737H20.6047V12Z" fill="white" />
                    <path d="M21.0698 12H21.5349V12.4737H21.0698V12Z" fill="white" />
                    <path d="M21.5349 12H22V12.4737H21.5349V12Z" fill="white" />
                    <path d="M3.86047 12.4737H4.32558V12.9474H3.86047V12.4737Z" fill="white" />
                    <path d="M4.32558 12.4737H4.7907V12.9474H4.32558V12.4737Z" fill="white" />
                    <path d="M4.7907 12.4737H5.25581V12.9474H4.7907V12.4737Z" fill="white" />
                    <path d="M5.25581 12.4737H5.72093V12.9474H5.25581V12.4737Z" fill="white" />
                    <path d="M5.72093 12.4737H6.18605V12.9474H5.72093V12.4737Z" fill="white" />
                    <path d="M6.18605 12.4737H6.65116V12.9474H6.18605V12.4737Z" fill="white" />
                    <path d="M6.65116 12.4737H7.11628V12.9474H6.65116V12.4737Z" fill="white" />
                    <path d="M7.11628 12.4737H7.5814V12.9474H7.11628V12.4737Z" fill="white" />
                    <path d="M7.5814 12.4737H8.04651V12.9474H7.5814V12.4737Z" fill="white" />
                    <path d="M8.04651 12.4737H8.51163V12.9474H8.04651V12.4737Z" fill="white" />
                    <path d="M8.51163 12.4737H8.97674V12.9474H8.51163V12.4737Z" fill="white" />
                    <path d="M8.97674 12.4737H9.44186V12.9474H8.97674V12.4737Z" fill="white" />
                    <path d="M10.3721 12.4737H10.8372V12.9474H10.3721V12.4737Z" fill="white" />
                    <path d="M10.8372 12.4737H11.3023V12.9474H10.8372V12.4737Z" fill="white" />
                    <path d="M11.3023 12.4737H11.7674V12.9474H11.3023V12.4737Z" fill="white" />
                    <path d="M11.7674 12.4737H12.2326V12.9474H11.7674V12.4737Z" fill="white" />
                    <path d="M12.2326 12.4737H12.6977V12.9474H12.2326V12.4737Z" fill="white" />
                    <path d="M19.6744 12.4737H20.1395V12.9474H19.6744V12.4737Z" fill="white" />
                    <path d="M20.1395 12.4737H20.6047V12.9474H20.1395V12.4737Z" fill="white" />
                    <path d="M20.6047 12.4737H21.0698V12.9474H20.6047V12.4737Z" fill="white" />
                    <path d="M21.0698 12.4737H21.5349V12.9474H21.0698V12.4737Z" fill="white" />
                    <path d="M21.5349 12.4737H22V12.9474H21.5349V12.4737Z" fill="white" />
                    <path d="M2 12.9474H2.46512V13.4211H2V12.9474Z" fill="white" />
                    <path d="M4.7907 12.9474H5.25581V13.4211H4.7907V12.9474Z" fill="white" />
                    <path d="M5.25581 12.9474H5.72093V13.4211H5.25581V12.9474Z" fill="white" />
                    <path d="M5.72093 12.9474H6.18605V13.4211H5.72093V12.9474Z" fill="white" />
                    <path d="M6.18605 12.9474H6.65116V13.4211H6.18605V12.9474Z" fill="white" />
                    <path d="M6.65116 12.9474H7.11628V13.4211H6.65116V12.9474Z" fill="white" />
                    <path d="M7.11628 12.9474H7.5814V13.4211H7.11628V12.9474Z" fill="white" />
                    <path d="M7.5814 12.9474H8.04651V13.4211H7.5814V12.9474Z" fill="white" />
                    <path d="M8.04651 12.9474H8.51163V13.4211H8.04651V12.9474Z" fill="white" />
                    <path d="M8.51163 12.9474H8.97674V13.4211H8.51163V12.9474Z" fill="white" />
                    <path d="M8.97674 12.9474H9.44186V13.4211H8.97674V12.9474Z" fill="white" />
                    <path d="M10.8372 12.9474H11.3023V13.4211H10.8372V12.9474Z" fill="white" />
                    <path d="M11.3023 12.9474H11.7674V13.4211H11.3023V12.9474Z" fill="white" />
                    <path d="M11.7674 12.9474H12.2326V13.4211H11.7674V12.9474Z" fill="white" />
                    <path d="M12.2326 12.9474H12.6977V13.4211H12.2326V12.9474Z" fill="white" />
                    <path d="M12.6977 12.9474H13.1628V13.4211H12.6977V12.9474Z" fill="white" />
                    <path d="M13.1628 12.9474H13.6279V13.4211H13.1628V12.9474Z" fill="white" />
                    <path d="M13.6279 12.9474H14.093V13.4211H13.6279V12.9474Z" fill="white" />
                    <path d="M14.093 12.9474H14.5581V13.4211H14.093V12.9474Z" fill="white" />
                    <path d="M17.814 12.9474H18.2791V13.4211H17.814V12.9474Z" fill="white" />
                    <path d="M18.2791 12.9474H18.7442V13.4211H18.2791V12.9474Z" fill="white" />
                    <path d="M18.7442 12.9474H19.2093V13.4211H18.7442V12.9474Z" fill="white" />
                    <path d="M19.2093 12.9474H19.6744V13.4211H19.2093V12.9474Z" fill="white" />
                    <path d="M19.6744 12.9474H20.1395V13.4211H19.6744V12.9474Z" fill="white" />
                    <path d="M20.1395 12.9474H20.6047V13.4211H20.1395V12.9474Z" fill="white" />
                    <path d="M20.6047 12.9474H21.0698V13.4211H20.6047V12.9474Z" fill="white" />
                    <path d="M21.0698 12.9474H21.5349V13.4211H21.0698V12.9474Z" fill="white" />
                    <path d="M2 13.4211H2.46512V13.8947H2V13.4211Z" fill="white" />
                    <path d="M2.46512 13.4211H2.93023V13.8947H2.46512V13.4211Z" fill="white" />
                    <path d="M2.93023 13.4211H3.39535V13.8947H2.93023V13.4211Z" fill="white" />
                    <path d="M11.3023 13.4211H11.7674V13.8947H11.3023V13.4211Z" fill="white" />
                    <path d="M11.7674 13.4211H12.2326V13.8947H11.7674V13.4211Z" fill="white" />
                    <path d="M12.2326 13.4211H12.6977V13.8947H12.2326V13.4211Z" fill="white" />
                    <path d="M12.6977 13.4211H13.1628V13.8947H12.6977V13.4211Z" fill="white" />
                    <path d="M13.1628 13.4211H13.6279V13.8947H13.1628V13.4211Z" fill="white" />
                    <path d="M13.6279 13.4211H14.093V13.8947H13.6279V13.4211Z" fill="white" />
                    <path d="M14.093 13.4211H14.5581V13.8947H14.093V13.4211Z" fill="white" />
                    <path d="M14.5581 13.4211H15.0233V13.8947H14.5581V13.4211Z" fill="white" />
                    <path d="M15.0233 13.4211H15.4884V13.8947H15.0233V13.4211Z" fill="white" />
                    <path d="M15.4884 13.4211H15.9535V13.8947H15.4884V13.4211Z" fill="white" />
                    <path d="M15.9535 13.4211H16.4186V13.8947H15.9535V13.4211Z" fill="white" />
                    <path d="M16.4186 13.4211H16.8837V13.8947H16.4186V13.4211Z" fill="white" />
                    <path d="M16.8837 13.4211H17.3488V13.8947H16.8837V13.4211Z" fill="white" />
                    <path d="M17.3488 13.4211H17.814V13.8947H17.3488V13.4211Z" fill="white" />
                    <path d="M17.814 13.4211H18.2791V13.8947H17.814V13.4211Z" fill="white" />
                    <path d="M18.2791 13.4211H18.7442V13.8947H18.2791V13.4211Z" fill="white" />
                    <path d="M18.7442 13.4211H19.2093V13.8947H18.7442V13.4211Z" fill="white" />
                    <path d="M19.2093 13.4211H19.6744V13.8947H19.2093V13.4211Z" fill="white" />
                    <path d="M19.6744 13.4211H20.1395V13.8947H19.6744V13.4211Z" fill="white" />
                    <path d="M20.1395 13.4211H20.6047V13.8947H20.1395V13.4211Z" fill="white" />
                    <path d="M20.6047 13.4211H21.0698V13.8947H20.6047V13.4211Z" fill="white" />
                    <path d="M2.46512 13.8947H2.93023V14.3684H2.46512V13.8947Z" fill="white" />
                    <path d="M2.93023 13.8947H3.39535V14.3684H2.93023V13.8947Z" fill="white" />
                    <path d="M3.39535 13.8947H3.86047V14.3684H3.39535V13.8947Z" fill="white" />
                    <path d="M11.7674 13.8947H12.2326V14.3684H11.7674V13.8947Z" fill="white" />
                    <path d="M12.2326 13.8947H12.6977V14.3684H12.2326V13.8947Z" fill="white" />
                    <path d="M12.6977 13.8947H13.1628V14.3684H12.6977V13.8947Z" fill="white" />
                    <path d="M13.1628 13.8947H13.6279V14.3684H13.1628V13.8947Z" fill="white" />
                    <path d="M13.6279 13.8947H14.093V14.3684H13.6279V13.8947Z" fill="white" />
                    <path d="M14.093 13.8947H14.5581V14.3684H14.093V13.8947Z" fill="white" />
                    <path d="M14.5581 13.8947H15.0233V14.3684H14.5581V13.8947Z" fill="white" />
                    <path d="M15.0233 13.8947H15.4884V14.3684H15.0233V13.8947Z" fill="white" />
                    <path d="M15.4884 13.8947H15.9535V14.3684H15.4884V13.8947Z" fill="white" />
                    <path d="M15.9535 13.8947H16.4186V14.3684H15.9535V13.8947Z" fill="white" />
                    <path d="M16.4186 13.8947H16.8837V14.3684H16.4186V13.8947Z" fill="white" />
                    <path d="M16.8837 13.8947H17.3488V14.3684H16.8837V13.8947Z" fill="white" />
                    <path d="M17.3488 13.8947H17.814V14.3684H17.3488V13.8947Z" fill="white" />
                    <path d="M17.814 13.8947H18.2791V14.3684H17.814V13.8947Z" fill="white" />
                    <path d="M18.2791 13.8947H18.7442V14.3684H18.2791V13.8947Z" fill="white" />
                    <path d="M18.7442 13.8947H19.2093V14.3684H18.7442V13.8947Z" fill="white" />
                    <path d="M19.2093 13.8947H19.6744V14.3684H19.2093V13.8947Z" fill="white" />
                    <path d="M19.6744 13.8947H20.1395V14.3684H19.6744V13.8947Z" fill="white" />
                    <path d="M2.46512 14.3684H2.93023V14.8421H2.46512V14.3684Z" fill="white" />
                    <path d="M2.93023 14.3684H3.39535V14.8421H2.93023V14.3684Z" fill="white" />
                    <path d="M3.39535 14.3684H3.86047V14.8421H3.39535V14.3684Z" fill="white" />
                    <path d="M3.86047 14.3684H4.32558V14.8421H3.86047V14.3684Z" fill="white" />
                    <path d="M4.32558 14.3684H4.7907V14.8421H4.32558V14.3684Z" fill="white" />
                    <path d="M4.7907 14.3684H5.25581V14.8421H4.7907V14.3684Z" fill="white" />
                    <path d="M5.25581 14.3684H5.72093V14.8421H5.25581V14.3684Z" fill="white" />
                    <path d="M10.3721 14.3684H10.8372V14.8421H10.3721V14.3684Z" fill="white" />
                    <path d="M13.1628 14.3684H13.6279V14.8421H13.1628V14.3684Z" fill="white" />
                    <path d="M13.6279 14.3684H14.093V14.8421H13.6279V14.3684Z" fill="white" />
                    <path d="M14.093 14.3684H14.5581V14.8421H14.093V14.3684Z" fill="white" />
                    <path d="M14.5581 14.3684H15.0233V14.8421H14.5581V14.3684Z" fill="white" />
                    <path d="M15.0233 14.3684H15.4884V14.8421H15.0233V14.3684Z" fill="white" />
                    <path d="M15.4884 14.3684H15.9535V14.8421H15.4884V14.3684Z" fill="white" />
                    <path d="M15.9535 14.3684H16.4186V14.8421H15.9535V14.3684Z" fill="white" />
                    <path d="M16.4186 14.3684H16.8837V14.8421H16.4186V14.3684Z" fill="white" />
                    <path d="M16.8837 14.3684H17.3488V14.8421H16.8837V14.3684Z" fill="white" />
                    <path d="M17.3488 14.3684H17.814V14.8421H17.3488V14.3684Z" fill="white" />
                    <path d="M17.814 14.3684H18.2791V14.8421H17.814V14.3684Z" fill="white" />
                    <path d="M18.2791 14.3684H18.7442V14.8421H18.2791V14.3684Z" fill="white" />
                    <path d="M18.7442 14.3684H19.2093V14.8421H18.7442V14.3684Z" fill="white" />
                    <path d="M21.5349 14.3684H22V14.8421H21.5349V14.3684Z" fill="white" />
                    <path d="M2.93023 14.8421H3.39535V15.3158H2.93023V14.8421Z" fill="white" />
                    <path d="M3.39535 14.8421H3.86047V15.3158H3.39535V14.8421Z" fill="white" />
                    <path d="M3.86047 14.8421H4.32558V15.3158H3.86047V14.8421Z" fill="white" />
                    <path d="M4.32558 14.8421H4.7907V15.3158H4.32558V14.8421Z" fill="white" />
                    <path d="M4.7907 14.8421H5.25581V15.3158H4.7907V14.8421Z" fill="white" />
                    <path d="M5.25581 14.8421H5.72093V15.3158H5.25581V14.8421Z" fill="white" />
                    <path d="M5.72093 14.8421H6.18605V15.3158H5.72093V14.8421Z" fill="white" />
                    <path d="M6.18605 14.8421H6.65116V15.3158H6.18605V14.8421Z" fill="white" />
                    <path d="M6.65116 14.8421H7.11628V15.3158H6.65116V14.8421Z" fill="white" />
                    <path d="M7.11628 14.8421H7.5814V15.3158H7.11628V14.8421Z" fill="white" />
                    <path d="M7.5814 14.8421H8.04651V15.3158H7.5814V14.8421Z" fill="white" />
                    <path d="M8.04651 14.8421H8.51163V15.3158H8.04651V14.8421Z" fill="white" />
                    <path d="M8.51163 14.8421H8.97674V15.3158H8.51163V14.8421Z" fill="white" />
                    <path d="M8.97674 14.8421H9.44186V15.3158H8.97674V14.8421Z" fill="white" />
                    <path d="M10.3721 14.8421H10.8372V15.3158H10.3721V14.8421Z" fill="white" />
                    <path d="M10.8372 14.8421H11.3023V15.3158H10.8372V14.8421Z" fill="white" />
                    <path d="M21.0698 14.8421H21.5349V15.3158H21.0698V14.8421Z" fill="white" />
                    <path d="M21.5349 14.8421H22V15.3158H21.5349V14.8421Z" fill="white" />
                    <path d="M3.39535 15.3158H3.86047V15.7895H3.39535V15.3158Z" fill="white" />
                    <path d="M3.86047 15.3158H4.32558V15.7895H3.86047V15.3158Z" fill="white" />
                    <path d="M4.32558 15.3158H4.7907V15.7895H4.32558V15.3158Z" fill="white" />
                    <path d="M4.7907 15.3158H5.25581V15.7895H4.7907V15.3158Z" fill="white" />
                    <path d="M5.25581 15.3158H5.72093V15.7895H5.25581V15.3158Z" fill="white" />
                    <path d="M5.72093 15.3158H6.18605V15.7895H5.72093V15.3158Z" fill="white" />
                    <path d="M6.18605 15.3158H6.65116V15.7895H6.18605V15.3158Z" fill="white" />
                    <path d="M6.65116 15.3158H7.11628V15.7895H6.65116V15.3158Z" fill="white" />
                    <path d="M7.11628 15.3158H7.5814V15.7895H7.11628V15.3158Z" fill="white" />
                    <path d="M7.5814 15.3158H8.04651V15.7895H7.5814V15.3158Z" fill="white" />
                    <path d="M8.04651 15.3158H8.51163V15.7895H8.04651V15.3158Z" fill="white" />
                    <path d="M8.51163 15.3158H8.97674V15.7895H8.51163V15.3158Z" fill="white" />
                    <path d="M8.97674 15.3158H9.44186V15.7895H8.97674V15.3158Z" fill="white" />
                    <path d="M10.3721 15.3158H10.8372V15.7895H10.3721V15.3158Z" fill="white" />
                    <path d="M10.8372 15.3158H11.3023V15.7895H10.8372V15.3158Z" fill="white" />
                    <path d="M11.3023 15.3158H11.7674V15.7895H11.3023V15.3158Z" fill="white" />
                    <path d="M20.1395 15.3158H20.6047V15.7895H20.1395V15.3158Z" fill="white" />
                    <path d="M20.6047 15.3158H21.0698V15.7895H20.6047V15.3158Z" fill="white" />
                    <path d="M21.0698 15.3158H21.5349V15.7895H21.0698V15.3158Z" fill="white" />
                    <path d="M21.5349 15.3158H22V15.7895H21.5349V15.3158Z" fill="white" />
                    <path d="M2 15.7895H2.46512V16.2632H2V15.7895Z" fill="white" />
                    <path d="M4.7907 15.7895H5.25581V16.2632H4.7907V15.7895Z" fill="white" />
                    <path d="M5.25581 15.7895H5.72093V16.2632H5.25581V15.7895Z" fill="white" />
                    <path d="M5.72093 15.7895H6.18605V16.2632H5.72093V15.7895Z" fill="white" />
                    <path d="M6.18605 15.7895H6.65116V16.2632H6.18605V15.7895Z" fill="white" />
                    <path d="M6.65116 15.7895H7.11628V16.2632H6.65116V15.7895Z" fill="white" />
                    <path d="M7.11628 15.7895H7.5814V16.2632H7.11628V15.7895Z" fill="white" />
                    <path d="M7.5814 15.7895H8.04651V16.2632H7.5814V15.7895Z" fill="white" />
                    <path d="M8.04651 15.7895H8.51163V16.2632H8.04651V15.7895Z" fill="white" />
                    <path d="M8.51163 15.7895H8.97674V16.2632H8.51163V15.7895Z" fill="white" />
                    <path d="M8.97674 15.7895H9.44186V16.2632H8.97674V15.7895Z" fill="white" />
                    <path d="M10.3721 15.7895H10.8372V16.2632H10.3721V15.7895Z" fill="white" />
                    <path d="M10.8372 15.7895H11.3023V16.2632H10.8372V15.7895Z" fill="white" />
                    <path d="M11.3023 15.7895H11.7674V16.2632H11.3023V15.7895Z" fill="white" />
                    <path d="M11.7674 15.7895H12.2326V16.2632H11.7674V15.7895Z" fill="white" />
                    <path d="M12.2326 15.7895H12.6977V16.2632H12.2326V15.7895Z" fill="white" />
                    <path d="M12.6977 15.7895H13.1628V16.2632H12.6977V15.7895Z" fill="white" />
                    <path d="M19.2093 15.7895H19.6744V16.2632H19.2093V15.7895Z" fill="white" />
                    <path d="M19.6744 15.7895H20.1395V16.2632H19.6744V15.7895Z" fill="white" />
                    <path d="M20.1395 15.7895H20.6047V16.2632H20.1395V15.7895Z" fill="white" />
                    <path d="M20.6047 15.7895H21.0698V16.2632H20.6047V15.7895Z" fill="white" />
                    <path d="M21.0698 15.7895H21.5349V16.2632H21.0698V15.7895Z" fill="white" />
                    <path d="M2 16.2632H2.46512V16.7368H2V16.2632Z" fill="white" />
                    <path d="M2.46512 16.2632H2.93023V16.7368H2.46512V16.2632Z" fill="white" />
                    <path d="M10.8372 16.2632H11.3023V16.7368H10.8372V16.2632Z" fill="white" />
                    <path d="M11.3023 16.2632H11.7674V16.7368H11.3023V16.2632Z" fill="white" />
                    <path d="M11.7674 16.2632H12.2326V16.7368H11.7674V16.2632Z" fill="white" />
                    <path d="M12.2326 16.2632H12.6977V16.7368H12.2326V16.2632Z" fill="white" />
                    <path d="M12.6977 16.2632H13.1628V16.7368H12.6977V16.2632Z" fill="white" />
                    <path d="M13.1628 16.2632H13.6279V16.7368H13.1628V16.2632Z" fill="white" />
                    <path d="M13.6279 16.2632H14.093V16.7368H13.6279V16.2632Z" fill="white" />
                    <path d="M14.093 16.2632H14.5581V16.7368H14.093V16.2632Z" fill="white" />
                    <path d="M14.5581 16.2632H15.0233V16.7368H14.5581V16.2632Z" fill="white" />
                    <path d="M15.0233 16.2632H15.4884V16.7368H15.0233V16.2632Z" fill="white" />
                    <path d="M15.4884 16.2632H15.9535V16.7368H15.4884V16.2632Z" fill="white" />
                    <path d="M15.9535 16.2632H16.4186V16.7368H15.9535V16.2632Z" fill="white" />
                    <path d="M16.4186 16.2632H16.8837V16.7368H16.4186V16.2632Z" fill="white" />
                    <path d="M16.8837 16.2632H17.3488V16.7368H16.8837V16.2632Z" fill="white" />
                    <path d="M17.3488 16.2632H17.814V16.7368H17.3488V16.2632Z" fill="white" />
                    <path d="M17.814 16.2632H18.2791V16.7368H17.814V16.2632Z" fill="white" />
                    <path d="M18.2791 16.2632H18.7442V16.7368H18.2791V16.2632Z" fill="white" />
                    <path d="M18.7442 16.2632H19.2093V16.7368H18.7442V16.2632Z" fill="white" />
                    <path d="M19.2093 16.2632H19.6744V16.7368H19.2093V16.2632Z" fill="white" />
                    <path d="M19.6744 16.2632H20.1395V16.7368H19.6744V16.2632Z" fill="white" />
                    <path d="M20.1395 16.2632H20.6047V16.7368H20.1395V16.2632Z" fill="white" />
                    <path d="M20.6047 16.2632H21.0698V16.7368H20.6047V16.2632Z" fill="white" />
                    <path d="M21.0698 16.2632H21.5349V16.7368H21.0698V16.2632Z" fill="white" />
                    <path d="M2 16.7368H2.46512V17.2105H2V16.7368Z" fill="white" />
                    <path d="M2.46512 16.7368H2.93023V17.2105H2.46512V16.7368Z" fill="white" />
                    <path d="M2.93023 16.7368H3.39535V17.2105H2.93023V16.7368Z" fill="white" />
                    <path d="M3.39535 16.7368H3.86047V17.2105H3.39535V16.7368Z" fill="white" />
                    <path d="M11.3023 16.7368H11.7674V17.2105H11.3023V16.7368Z" fill="white" />
                    <path d="M11.7674 16.7368H12.2326V17.2105H11.7674V16.7368Z" fill="white" />
                    <path d="M12.2326 16.7368H12.6977V17.2105H12.2326V16.7368Z" fill="white" />
                    <path d="M12.6977 16.7368H13.1628V17.2105H12.6977V16.7368Z" fill="white" />
                    <path d="M13.1628 16.7368H13.6279V17.2105H13.1628V16.7368Z" fill="white" />
                    <path d="M13.6279 16.7368H14.093V17.2105H13.6279V16.7368Z" fill="white" />
                    <path d="M14.093 16.7368H14.5581V17.2105H14.093V16.7368Z" fill="white" />
                    <path d="M14.5581 16.7368H15.0233V17.2105H14.5581V16.7368Z" fill="white" />
                    <path d="M15.0233 16.7368H15.4884V17.2105H15.0233V16.7368Z" fill="white" />
                    <path d="M15.4884 16.7368H15.9535V17.2105H15.4884V16.7368Z" fill="white" />
                    <path d="M15.9535 16.7368H16.4186V17.2105H15.9535V16.7368Z" fill="white" />
                    <path d="M16.4186 16.7368H16.8837V17.2105H16.4186V16.7368Z" fill="white" />
                    <path d="M16.8837 16.7368H17.3488V17.2105H16.8837V16.7368Z" fill="white" />
                    <path d="M17.3488 16.7368H17.814V17.2105H17.3488V16.7368Z" fill="white" />
                    <path d="M17.814 16.7368H18.2791V17.2105H17.814V16.7368Z" fill="white" />
                    <path d="M18.2791 16.7368H18.7442V17.2105H18.2791V16.7368Z" fill="white" />
                    <path d="M18.7442 16.7368H19.2093V17.2105H18.7442V16.7368Z" fill="white" />
                    <path d="M19.2093 16.7368H19.6744V17.2105H19.2093V16.7368Z" fill="white" />
                    <path d="M19.6744 16.7368H20.1395V17.2105H19.6744V16.7368Z" fill="white" />
                    <path d="M20.1395 16.7368H20.6047V17.2105H20.1395V16.7368Z" fill="white" />
                    <path d="M2.46512 17.2105H2.93023V17.6842H2.46512V17.2105Z" fill="white" />
                    <path d="M2.93023 17.2105H3.39535V17.6842H2.93023V17.2105Z" fill="white" />
                    <path d="M3.39535 17.2105H3.86047V17.6842H3.39535V17.2105Z" fill="white" />
                    <path d="M3.86047 17.2105H4.32558V17.6842H3.86047V17.2105Z" fill="white" />
                    <path d="M4.32558 17.2105H4.7907V17.6842H4.32558V17.2105Z" fill="white" />
                    <path d="M4.7907 17.2105H5.25581V17.6842H4.7907V17.2105Z" fill="white" />
                    <path d="M10.3721 17.2105H10.8372V17.6842H10.3721V17.2105Z" fill="white" />
                    <path d="M12.2326 17.2105H12.6977V17.6842H12.2326V17.2105Z" fill="white" />
                    <path d="M12.6977 17.2105H13.1628V17.6842H12.6977V17.2105Z" fill="white" />
                    <path d="M13.1628 17.2105H13.6279V17.6842H13.1628V17.2105Z" fill="white" />
                    <path d="M13.6279 17.2105H14.093V17.6842H13.6279V17.2105Z" fill="white" />
                    <path d="M14.093 17.2105H14.5581V17.6842H14.093V17.2105Z" fill="white" />
                    <path d="M14.5581 17.2105H15.0233V17.6842H14.5581V17.2105Z" fill="white" />
                    <path d="M15.0233 17.2105H15.4884V17.6842H15.0233V17.2105Z" fill="white" />
                    <path d="M15.4884 17.2105H15.9535V17.6842H15.4884V17.2105Z" fill="white" />
                    <path d="M15.9535 17.2105H16.4186V17.6842H15.9535V17.2105Z" fill="white" />
                    <path d="M16.4186 17.2105H16.8837V17.6842H16.4186V17.2105Z" fill="white" />
                    <path d="M16.8837 17.2105H17.3488V17.6842H16.8837V17.2105Z" fill="white" />
                    <path d="M17.3488 17.2105H17.814V17.6842H17.3488V17.2105Z" fill="white" />
                    <path d="M17.814 17.2105H18.2791V17.6842H17.814V17.2105Z" fill="white" />
                    <path d="M18.2791 17.2105H18.7442V17.6842H18.2791V17.2105Z" fill="white" />
                    <path d="M18.7442 17.2105H19.2093V17.6842H18.7442V17.2105Z" fill="white" />
                    <path d="M19.2093 17.2105H19.6744V17.6842H19.2093V17.2105Z" fill="white" />
                    <path d="M21.5349 17.2105H22V17.6842H21.5349V17.2105Z" fill="white" />
                    <path d="M2.93023 17.6842H3.39535V18.1579H2.93023V17.6842Z" fill="white" />
                    <path d="M3.39535 17.6842H3.86047V18.1579H3.39535V17.6842Z" fill="white" />
                    <path d="M3.86047 17.6842H4.32558V18.1579H3.86047V17.6842Z" fill="white" />
                    <path d="M4.32558 17.6842H4.7907V18.1579H4.32558V17.6842Z" fill="white" />
                    <path d="M4.7907 17.6842H5.25581V18.1579H4.7907V17.6842Z" fill="white" />
                    <path d="M5.25581 17.6842H5.72093V18.1579H5.25581V17.6842Z" fill="white" />
                    <path d="M5.72093 17.6842H6.18605V18.1579H5.72093V17.6842Z" fill="white" />
                    <path d="M6.18605 17.6842H6.65116V18.1579H6.18605V17.6842Z" fill="white" />
                    <path d="M6.65116 17.6842H7.11628V18.1579H6.65116V17.6842Z" fill="white" />
                    <path d="M7.11628 17.6842H7.5814V18.1579H7.11628V17.6842Z" fill="white" />
                    <path d="M7.5814 17.6842H8.04651V18.1579H7.5814V17.6842Z" fill="white" />
                    <path d="M8.04651 17.6842H8.51163V18.1579H8.04651V17.6842Z" fill="white" />
                    <path d="M8.51163 17.6842H8.97674V18.1579H8.51163V17.6842Z" fill="white" />
                    <path d="M8.97674 17.6842H9.44186V18.1579H8.97674V17.6842Z" fill="white" />
                    <path d="M10.3721 17.6842H10.8372V18.1579H10.3721V17.6842Z" fill="white" />
                    <path d="M10.8372 17.6842H11.3023V18.1579H10.8372V17.6842Z" fill="white" />
                    <path d="M14.093 17.6842H14.5581V18.1579H14.093V17.6842Z" fill="white" />
                    <path d="M14.5581 17.6842H15.0233V18.1579H14.5581V17.6842Z" fill="white" />
                    <path d="M15.0233 17.6842H15.4884V18.1579H15.0233V17.6842Z" fill="white" />
                    <path d="M15.4884 17.6842H15.9535V18.1579H15.4884V17.6842Z" fill="white" />
                    <path d="M15.9535 17.6842H16.4186V18.1579H15.9535V17.6842Z" fill="white" />
                    <path d="M16.4186 17.6842H16.8837V18.1579H16.4186V17.6842Z" fill="white" />
                    <path d="M16.8837 17.6842H17.3488V18.1579H16.8837V17.6842Z" fill="white" />
                    <path d="M17.3488 17.6842H17.814V18.1579H17.3488V17.6842Z" fill="white" />
                    <path d="M17.814 17.6842H18.2791V18.1579H17.814V17.6842Z" fill="white" />
                    <path d="M21.0698 17.6842H21.5349V18.1579H21.0698V17.6842Z" fill="white" />
                    <path d="M21.5349 17.6842H22V18.1579H21.5349V17.6842Z" fill="white" />
                    <path d="M3.39535 18.1579H3.86047V18.6316H3.39535V18.1579Z" fill="white" />
                    <path d="M3.86047 18.1579H4.32558V18.6316H3.86047V18.1579Z" fill="white" />
                    <path d="M4.32558 18.1579H4.7907V18.6316H4.32558V18.1579Z" fill="white" />
                    <path d="M4.7907 18.1579H5.25581V18.6316H4.7907V18.1579Z" fill="white" />
                    <path d="M5.25581 18.1579H5.72093V18.6316H5.25581V18.1579Z" fill="white" />
                    <path d="M5.72093 18.1579H6.18605V18.6316H5.72093V18.1579Z" fill="white" />
                    <path d="M6.18605 18.1579H6.65116V18.6316H6.18605V18.1579Z" fill="white" />
                    <path d="M6.65116 18.1579H7.11628V18.6316H6.65116V18.1579Z" fill="white" />
                    <path d="M7.11628 18.1579H7.5814V18.6316H7.11628V18.1579Z" fill="white" />
                    <path d="M7.5814 18.1579H8.04651V18.6316H7.5814V18.1579Z" fill="white" />
                    <path d="M8.04651 18.1579H8.51163V18.6316H8.04651V18.1579Z" fill="white" />
                    <path d="M8.51163 18.1579H8.97674V18.6316H8.51163V18.1579Z" fill="white" />
                    <path d="M8.97674 18.1579H9.44186V18.6316H8.97674V18.1579Z" fill="white" />
                    <path d="M10.3721 18.1579H10.8372V18.6316H10.3721V18.1579Z" fill="white" />
                    <path d="M10.8372 18.1579H11.3023V18.6316H10.8372V18.1579Z" fill="white" />
                    <path d="M11.3023 18.1579H11.7674V18.6316H11.3023V18.1579Z" fill="white" />
                    <path d="M20.6047 18.1579H21.0698V18.6316H20.6047V18.1579Z" fill="white" />
                    <path d="M21.0698 18.1579H21.5349V18.6316H21.0698V18.1579Z" fill="white" />
                    <path d="M21.5349 18.1579H22V18.6316H21.5349V18.1579Z" fill="white" />
                    <path d="M4.32558 18.6316H4.7907V19.1053H4.32558V18.6316Z" fill="white" />
                    <path d="M4.7907 18.6316H5.25581V19.1053H4.7907V18.6316Z" fill="white" />
                    <path d="M5.25581 18.6316H5.72093V19.1053H5.25581V18.6316Z" fill="white" />
                    <path d="M5.72093 18.6316H6.18605V19.1053H5.72093V18.6316Z" fill="white" />
                    <path d="M6.18605 18.6316H6.65116V19.1053H6.18605V18.6316Z" fill="white" />
                    <path d="M6.65116 18.6316H7.11628V19.1053H6.65116V18.6316Z" fill="white" />
                    <path d="M7.11628 18.6316H7.5814V19.1053H7.11628V18.6316Z" fill="white" />
                    <path d="M7.5814 18.6316H8.04651V19.1053H7.5814V18.6316Z" fill="white" />
                    <path d="M8.04651 18.6316H8.51163V19.1053H8.04651V18.6316Z" fill="white" />
                    <path d="M8.51163 18.6316H8.97674V19.1053H8.51163V18.6316Z" fill="white" />
                    <path d="M8.97674 18.6316H9.44186V19.1053H8.97674V18.6316Z" fill="white" />
                    <path d="M10.3721 18.6316H10.8372V19.1053H10.3721V18.6316Z" fill="white" />
                    <path d="M10.8372 18.6316H11.3023V19.1053H10.8372V18.6316Z" fill="white" />
                    <path d="M11.3023 18.6316H11.7674V19.1053H11.3023V18.6316Z" fill="white" />
                    <path d="M11.7674 18.6316H12.2326V19.1053H11.7674V18.6316Z" fill="white" />
                    <path d="M12.2326 18.6316H12.6977V19.1053H12.2326V18.6316Z" fill="white" />
                    <path d="M19.6744 18.6316H20.1395V19.1053H19.6744V18.6316Z" fill="white" />
                    <path d="M20.1395 18.6316H20.6047V19.1053H20.1395V18.6316Z" fill="white" />
                    <path d="M20.6047 18.6316H21.0698V19.1053H20.6047V18.6316Z" fill="white" />
                    <path d="M21.0698 18.6316H21.5349V19.1053H21.0698V18.6316Z" fill="white" />
                    <path d="M21.5349 18.6316H22V19.1053H21.5349V18.6316Z" fill="white" />
                    <path d="M6.18605 19.1053H6.65116V19.5789H6.18605V19.1053Z" fill="white" />
                    <path d="M6.65116 19.1053H7.11628V19.5789H6.65116V19.1053Z" fill="white" />
                    <path d="M7.11628 19.1053H7.5814V19.5789H7.11628V19.1053Z" fill="white" />
                    <path d="M7.5814 19.1053H8.04651V19.5789H7.5814V19.1053Z" fill="white" />
                    <path d="M8.04651 19.1053H8.51163V19.5789H8.04651V19.1053Z" fill="white" />
                    <path d="M8.51163 19.1053H8.97674V19.5789H8.51163V19.1053Z" fill="white" />
                    <path d="M8.97674 19.1053H9.44186V19.5789H8.97674V19.1053Z" fill="white" />
                    <path d="M10.8372 19.1053H11.3023V19.5789H10.8372V19.1053Z" fill="white" />
                    <path d="M11.3023 19.1053H11.7674V19.5789H11.3023V19.1053Z" fill="white" />
                    <path d="M11.7674 19.1053H12.2326V19.5789H11.7674V19.1053Z" fill="white" />
                    <path d="M12.2326 19.1053H12.6977V19.5789H12.2326V19.1053Z" fill="white" />
                    <path d="M12.6977 19.1053H13.1628V19.5789H12.6977V19.1053Z" fill="white" />
                    <path d="M13.1628 19.1053H13.6279V19.5789H13.1628V19.1053Z" fill="white" />
                    <path d="M13.6279 19.1053H14.093V19.5789H13.6279V19.1053Z" fill="white" />
                    <path d="M14.093 19.1053H14.5581V19.5789H14.093V19.1053Z" fill="white" />
                    <path d="M14.5581 19.1053H15.0233V19.5789H14.5581V19.1053Z" fill="white" />
                    <path d="M15.0233 19.1053H15.4884V19.5789H15.0233V19.1053Z" fill="white" />
                    <path d="M16.8837 19.1053H17.3488V19.5789H16.8837V19.1053Z" fill="white" />
                    <path d="M17.3488 19.1053H17.814V19.5789H17.3488V19.1053Z" fill="white" />
                    <path d="M17.814 19.1053H18.2791V19.5789H17.814V19.1053Z" fill="white" />
                    <path d="M18.2791 19.1053H18.7442V19.5789H18.2791V19.1053Z" fill="white" />
                    <path d="M18.7442 19.1053H19.2093V19.5789H18.7442V19.1053Z" fill="white" />
                    <path d="M19.2093 19.1053H19.6744V19.5789H19.2093V19.1053Z" fill="white" />
                    <path d="M19.6744 19.1053H20.1395V19.5789H19.6744V19.1053Z" fill="white" />
                    <path d="M20.1395 19.1053H20.6047V19.5789H20.1395V19.1053Z" fill="white" />
                    <path d="M20.6047 19.1053H21.0698V19.5789H20.6047V19.1053Z" fill="white" />
                    <path d="M21.0698 19.1053H21.5349V19.5789H21.0698V19.1053Z" fill="white" />
                    <path d="M11.3023 19.5789H11.7674V20.0526H11.3023V19.5789Z" fill="white" />
                    <path d="M11.7674 19.5789H12.2326V20.0526H11.7674V19.5789Z" fill="white" />
                    <path d="M12.2326 19.5789H12.6977V20.0526H12.2326V19.5789Z" fill="white" />
                    <path d="M12.6977 19.5789H13.1628V20.0526H12.6977V19.5789Z" fill="white" />
                    <path d="M13.1628 19.5789H13.6279V20.0526H13.1628V19.5789Z" fill="white" />
                    <path d="M13.6279 19.5789H14.093V20.0526H13.6279V19.5789Z" fill="white" />
                    <path d="M14.093 19.5789H14.5581V20.0526H14.093V19.5789Z" fill="white" />
                    <path d="M14.5581 19.5789H15.0233V20.0526H14.5581V19.5789Z" fill="white" />
                    <path d="M15.0233 19.5789H15.4884V20.0526H15.0233V19.5789Z" fill="white" />
                    <path d="M15.4884 19.5789H15.9535V20.0526H15.4884V19.5789Z" fill="white" />
                    <path d="M15.9535 19.5789H16.4186V20.0526H15.9535V19.5789Z" fill="white" />
                    <path d="M16.4186 19.5789H16.8837V20.0526H16.4186V19.5789Z" fill="white" />
                    <path d="M16.8837 19.5789H17.3488V20.0526H16.8837V19.5789Z" fill="white" />
                    <path d="M17.3488 19.5789H17.814V20.0526H17.3488V19.5789Z" fill="white" />
                    <path d="M17.814 19.5789H18.2791V20.0526H17.814V19.5789Z" fill="white" />
                    <path d="M18.2791 19.5789H18.7442V20.0526H18.2791V19.5789Z" fill="white" />
                    <path d="M18.7442 19.5789H19.2093V20.0526H18.7442V19.5789Z" fill="white" />
                    <path d="M19.2093 19.5789H19.6744V20.0526H19.2093V19.5789Z" fill="white" />
                    <path d="M19.6744 19.5789H20.1395V20.0526H19.6744V19.5789Z" fill="white" />
                    <path d="M20.1395 19.5789H20.6047V20.0526H20.1395V19.5789Z" fill="white" />
                    <path d="M20.6047 19.5789H21.0698V20.0526H20.6047V19.5789Z" fill="white" />
                    <path d="M12.2326 20.0526H12.6977V20.5263H12.2326V20.0526Z" fill="white" />
                    <path d="M12.6977 20.0526H13.1628V20.5263H12.6977V20.0526Z" fill="white" />
                    <path d="M13.1628 20.0526H13.6279V20.5263H13.1628V20.0526Z" fill="white" />
                    <path d="M13.6279 20.0526H14.093V20.5263H13.6279V20.0526Z" fill="white" />
                    <path d="M14.093 20.0526H14.5581V20.5263H14.093V20.0526Z" fill="white" />
                    <path d="M14.5581 20.0526H15.0233V20.5263H14.5581V20.0526Z" fill="white" />
                    <path d="M15.0233 20.0526H15.4884V20.5263H15.0233V20.0526Z" fill="white" />
                    <path d="M15.4884 20.0526H15.9535V20.5263H15.4884V20.0526Z" fill="white" />
                    <path d="M15.9535 20.0526H16.4186V20.5263H15.9535V20.0526Z" fill="white" />
                    <path d="M16.4186 20.0526H16.8837V20.5263H16.4186V20.0526Z" fill="white" />
                    <path d="M16.8837 20.0526H17.3488V20.5263H16.8837V20.0526Z" fill="white" />
                    <path d="M17.3488 20.0526H17.814V20.5263H17.3488V20.0526Z" fill="white" />
                    <path d="M17.814 20.0526H18.2791V20.5263H17.814V20.0526Z" fill="white" />
                    <path d="M18.2791 20.0526H18.7442V20.5263H18.2791V20.0526Z" fill="white" />
                    <path d="M18.7442 20.0526H19.2093V20.5263H18.7442V20.0526Z" fill="white" />
                    <path d="M19.2093 20.0526H19.6744V20.5263H19.2093V20.0526Z" fill="white" />
                    <path d="M19.6744 20.0526H20.1395V20.5263H19.6744V20.0526Z" fill="white" />
                    <path d="M13.6279 20.5263H14.093V21H13.6279V20.5263Z" fill="white" />
                    <path d="M14.093 20.5263H14.5581V21H14.093V20.5263Z" fill="white" />
                    <path d="M14.5581 20.5263H15.0233V21H14.5581V20.5263Z" fill="white" />
                    <path d="M15.0233 20.5263H15.4884V21H15.0233V20.5263Z" fill="white" />
                    <path d="M15.4884 20.5263H15.9535V21H15.4884V20.5263Z" fill="white" />
                    <path d="M15.9535 20.5263H16.4186V21H15.9535V20.5263Z" fill="white" />
                    <path d="M16.4186 20.5263H16.8837V21H16.4186V20.5263Z" fill="white" />
                    <path d="M16.8837 20.5263H17.3488V21H16.8837V20.5263Z" fill="white" />
                    <path d="M17.3488 20.5263H17.814V21H17.3488V20.5263Z" fill="white" />
                    <path d="M17.814 20.5263H18.2791V21H17.814V20.5263Z" fill="white" />
                    <path d="M18.2791 20.5263H18.7442V21H18.2791V20.5263Z" fill="white" />
                </g>
            </g>
        </svg>

    );
}