import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import translationEN from './en.json';
import translationJP from './jp.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    jp: {
        translation: translationJP
    }
};

i18n
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en', // default language
        fallbackLng: 'en', // use English if the detected or selected language is not available
    });

export const TransComponents = {
    green: <span style={{ color: "#20A75C" }} />,
    br: <br />,
    title: <span className='font-bold text-custom-green' />,
    a: <a href="{{href}}" />,
};

export default i18n;
